import React from 'react';
import { redirect } from 'react-router-dom';
import { encryptStorage } from '../utils/storage';

const AuthContext = React.createContext({
  token: '',
  role: '',
  user: {},
  isLoggedIn: false,
  setToken: (token) => {},
  setRole: (role) => {},
  setUser: (user) => {},
});

export const AuthContextProvider = (props) => {

  const setToken = (token = '') => {

    if (token !== '') {
      contextValue.isLoggedIn = true;
      contextValue.token = token;
      encryptStorage.setItem('auth', contextValue);
    } else {
      contextValue.isLoggedIn = false;
      contextValue.token = null;
      encryptStorage.setItem('auth', contextValue);
    }
  };

  const setRole = (role = '') => {
    contextValue.role = role;
    encryptStorage.setItem('auth', contextValue);
  };

  const setUser = (user = {}) => {
    contextValue.user = user;
    encryptStorage.setItem('auth', contextValue);
  };

  const tmp = encryptStorage.getItem('auth');
  if (tmp) {
    tmp.setToken = setToken;
    tmp.setRole = setRole;
    tmp.setUser = setUser;
  };

  const contextValue =  tmp ? tmp : {
    token: null,
    role: null,
    user: null,
    isLoggedIn: false,
    setToken: setToken,
    setRole: setRole,
    setUser: setUser,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export async function checkIsLoggedIn(route) {
  const tmp = encryptStorage.getItem('auth');

  if (!tmp || !tmp.isLoggedIn) {
    return redirect('/auth');
  } else {
    if (typeof route === 'string' || route instanceof String) {
      return redirect(route);
    } else {
      return true;
    }
  }
}

export async function getToken() {

  const tmp = await encryptStorage.getItem('auth');
  if (!tmp || !tmp.isLoggedIn) {
    return null;
  } else {
    return tmp.token;
  }
}

export async function logOut() {
  const tmp = encryptStorage.getItem('auth');
  if (tmp) {
    tmp.setToken = null;
    tmp.setRole = null;
    tmp.setUser = null;
    tmp.isLoggedIn = false;
    encryptStorage.setItem('auth', tmp);
  };
}

export function checkUser(roles) {
  const role = encryptStorage.getItem('auth').role;

  if (roles.includes(role)) {
    return role;
  } else {
    return redirect('/patients');
  }
}

export default AuthContext;