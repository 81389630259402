import { useLoaderData, Link, useNavigation, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Api } from '../../utils/api';


const PatientForm = (props) => {
    const data = useLoaderData();
    const patient = data.patient;
    const meta = data.meta;
    const navigation = useNavigation();
    const navigate = useNavigate();
    const isSubmitting = navigation.status === 'sumbitting';

    const { register, formState: { errors }, handleSubmit } = useForm({
        defaultValues: patient
    });

    const onSubmit = (patientData) => {
        Api.setPatients(patientData);
        navigate('/patients/' + patientData.id);
    }

    return (
        <section className='lists'>
            <h1>{meta.title}</h1>
            <form className='form' onSubmit={handleSubmit(onSubmit)}>
                <p className='control'>
                    <input  {...register("id")}
                        id="id"
                        type="hidden"
                    />
                </p>
                <p className='control'>
                    <label>Име:</label>
                    <input {...register("name", { required: true })}
                        className={errors.name ? "error-input" : ""}
                        id="name"
                        type="text"
                    />
                </p>
                {errors.name && <p className='error-message'>Моля, въведете име.</p>}
                <p className='control'>
                    <label>Фамилия:</label>
                    <input {...register("family", { required: true })}
                        className={errors.family ? "error-input" : ""}
                        id="name"
                        type="text"
                    />
                </p>
                {errors.family && <p className='error-message'>Моля, въведете фамилия.</p>}
                <p className='control'>
                    <label>Възраст:</label>
                    <input {...register("age", { required: true, valueAsNumber: true, min: 0 })}
                        className={errors.age ? "error-input" : ""}
                        id="age"
                        type="number"
                    />
                </p>
                {errors.age && <p className='error-message'>Моля, въведете валидно число за възраст {'(>0)'}.</p>}
                <p className='control'>
                    <label>Професия:</label>
                    <input {...register("jobTitle")}
                        id="job"
                        type="text"
                    />
                </p>
                <p className='control'>
                    <label>Пол:</label>
                    <input {...register("sex", { required: true })} 
                        type="radio"
                        value="мъж"
                    /><label className='lbl-radio'>Мъж</label>
                    <input {...register("sex", { required: true })} 
                        type="radio"
                        value="жена"
                    /><label className='lbl-radio'>Жена</label>
                </p>
                {errors.sex && <p className='error-message'>Моля, изберете пол.</p>}
                <div className='action'>
                    <Link to=".." relative="path">
                        <button className='cancel'>Отказ</button>
                    </Link>
                    <button disabled={isSubmitting}>
                        {meta.submitButtonTitle}
                    </button>
                </div>
            </form>
        </section>
    );

    //}
};

export default PatientForm;