import { useContext, useRef } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import DiagnosticsSheetData from './DiagnosticsSheetData';
import { useReactToPrint } from 'react-to-print';
import AuthContext from '../../store/auth-context';

const DiagnosticsSheetDetails = () => {
    const authCtx = useContext(AuthContext);
    const diagnostic = useLoaderData();

    const navigate = useNavigate();

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            <div className="action">
                <button className='cancel' onClick={() => navigate('/diagnostics')}>← Диагностики</button>
                <button onClick={() => navigate('/diagnostics/' + diagnostic.id + '/edit')}>Редактирай</button>
                <button className="prev-next-step" onClick={handlePrint}>Принтирай</button>
            </div>
            <DiagnosticsSheetData data={{values: diagnostic, logo: authCtx.user.company.logo}} ref={componentRef} />
            <div className="action">
                <button className='cancel' onClick={() => navigate('/diagnostics')}>← Диагностики</button>
                <button onClick={() => navigate('/diagnostics/' + diagnostic.id + '/edit')}>Редактирай</button>
                <button className="prev-next-step" onClick={handlePrint}>Принтирай</button>
            </div>
        </>
    );
  }
  
  export default DiagnosticsSheetDetails;