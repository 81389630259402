import { useState } from "react";
//import { Link, useLoaderData, useNavigate } from "react-router-dom";
import { Link, useRouteLoaderData, useNavigate } from "react-router-dom";
import Modal from "../../components/UI/Modal";
import PersonalCard from "../../components/UI/PersonalCard";
import { Api } from "../../utils/api";

import DiagnosticsWrapper from "../../components/UI/DiagnosticsWrapper";

const PatientDetails = () => {
    const data = useRouteLoaderData('patient-details');

    const [modalIsShown, setModalIsShown] = useState(false);

    const navigate = useNavigate();

    const deletePatientHandler = (id) => {
        Api.deletePatient(id).then(async (result) => {
            if (result === true) {
                navigate('/patients');
            }
        });
    }

    return (
        <>
        {modalIsShown && 
        <Modal message={'Сигурни ли сте?'} onCancel={() => {setModalIsShown(false);}} onConfirm={() => {deletePatientHandler(data.patients.id); setModalIsShown(false);}}></Modal>}
        <section className='lists'>
            <h1>{data.patients.name}<span className="action in-title">
                <button className="edit"
                    onClick={() => {
                            navigate('edit');
                        }}>
                </button>
                <button className="delete" 
                    onClick={() => {
                            setModalIsShown(true);
                        }}>
                </button>
            </span></h1>
            <PersonalCard data={data.patients} />
        </section>
        <DiagnosticsWrapper data={data.diagnostics} patientId={data.patients.id} />
        <div className="back">
            <Link to=".." relative="path">
                <button>← Пациенти</button>
            </Link>
        </div>
        </>
    );
  }
  
  export default PatientDetails;
  