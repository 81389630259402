import { StateMachineProvider, createStore, useStateMachine } from 'little-state-machine';

import { Outlet} from 'react-router-dom';

// function log(store) {
//   }
  
//   createStore(
//       {
//         diagnostics: {
//             dateAdded: '',
//             id: '',
//             lastModified: '',
//             patientId: '',
//             patientName: '',
//           vitals: {
//             height: 0,
//             weight: 0,
//             bloodPressure: '',
//             pulse: 0,
//             saturation: 0,
//             medHistory: ''
//           },
//           blood: {
//             Mg: 0,
//             B12: 0,
//             Ca: 0,
//             D3: 0,
//           },
//           caliper: {
//             subscapula: 0,
//             biceps: 0,
//             triceps: 0,
//             nadialic: 0,
//           },
//           bodyScales: {
//             fat: 0,
//             hydration: 0,
//             muscles: 0,
//             bones: 0,
//             basalMetabolism: 0,
//             activeMetabolism: 0,
//           },
//         } // it's an object of your state
//       },
//       {
//         name: 'diagnostics store', // rename the store
//         middleWares: [ log ], // function to invoke each action
//         storageType: localStorage, // session/local storage (default to session)
        
//         persist: 'action' // onAction is default if not provided
//         // when 'none' is used then state is not persisted
//         // when 'action' is used then state is saved to the storage after store action is completed
//         // when 'beforeUnload' is used then state is saved to storage before page unloa
//      }
//     );

const ProtectedLayout = () => {
    return (
        <Outlet />
    );
};

export default ProtectedLayout;