import { useLoaderData, Link, useNavigation, useNavigate, useRouteLoaderData } from 'react-router-dom';
import { Controller, useForm } from "react-hook-form";
import { Api, userRoles } from '../../utils/api';
import selectCustomStyles from '../../components/UI/SelectCustomStyles';
import AsyncSelect from 'react-select/async';

const UserForm = () => {
    const data = useLoaderData();
    const user = data.user;
    const meta = data.meta;
    const navigation = useNavigation();
    const navigate = useNavigate();
    const isSubmitting = navigation.status === 'sumbitting';

    const checkedRole = useRouteLoaderData('users');

    const { register, getValues, formState: { errors }, clearErrors, handleSubmit, control } = useForm({
        defaultValues: user
    });

    const onSubmit = (userData) => {
        Api.setUsers(userData);
        navigate('/users/' + userData.id);
    }

    const companyOptions = (inputValue) =>
    new Promise(async (resolve) => {
        const result = await Api.getCompanies({name: inputValue});

        const options = [];
        result.companies.forEach((company) => {
            options.push({ value: company.id, label: company.name });
        });

        resolve(options);
    }
);

    return (
        <section className='lists'>
            <h1>{meta.title}</h1>
            <form className='form' onSubmit={handleSubmit(onSubmit)} noValidate>
                <p className='control'>
                    <input  {...register("id")}
                        id="id"
                        type="hidden"
                    />
                </p>
                {/* {checkedRole === userRoles.superAdmin && <>
                <p className='control'>
                    <label>Фирма:</label>
                </p>
                <div className='control'>
                    <Controller
                        control={control}
                        render={({field:{onChange, value, name, ref}}) => (
                            <AsyncSelect
                                inputRef={ref}
                                cacheOptions
                                defaultValue={meta.action != 'new' && [{value: getValues('company.id'), label: getValues('company.name')}]}
                                placeholder={meta.action === 'new' && 'Избери фирма...'}
                                loadOptions={companyOptions}
                                onChange={(selectedOption) => {
                                    clearErrors("company");
                                    onChange(selectedOption.value);
                                }}
                                styles={selectCustomStyles}
                            />
                            )}
                        name={'company'}
                        rules={{ required: true }}
                    />
                </div>
                </>}
                {errors.company && <p className='error-message'>Моля, изберете фирма.</p>} */}
                {checkedRole != userRoles.employee &&
                <p className='control'>
                    <label>Роля:</label>
                    <select {...register("role", { required: true })}>
                        {/* {checkedRole === userRoles.superAdmin && <option value={userRoles.superAdmin}>Супер администратор</option>} */}
                        <option value={userRoles.admin}>Администратор</option>
                        <option value={userRoles.employee}>Служител</option>
                    </select>
                </p>
                }
                {errors.role && <p className='error-message'>Моля, изберете тип потребител.</p>}
                <p className='control'>
                    <label>Име:</label>
                    <input {...register("name", { required: true })}
                        className={errors.name ? "error-input" : ""}
                        id="name"
                        type="text"
                    />
                </p>
                {errors.name && <p className='error-message'>Моля, въведете име.</p>}
                <p className='control'>
                    <label>Фамилия:</label>
                    <input {...register("family", { required: true })}
                        className={errors.family ? "error-input" : ""}
                        id="family"
                        type="text"
                    />
                </p>
                {errors.family && <p className='error-message'>Моля, въведете фамилия.</p>}
                <p className='control'>
                    <label>E-mail:</label>
                    <input {...register("email", { required: true, pattern: /\S+@\S+\.\S+/ })}
                        className={errors.email ? "error-input" : ""}
                        id="email"
                        type="email"
                    />
                </p>
                {errors.email && <p className='error-message'>Моля, въведете валиден e-mail.</p>}
                <p className='control'>
                    <label>Парола:</label>
                    <input {...register("password", { required: true })}
                        className={errors.password ? "error-input" : ""}
                        id="password"
                        type="password"
                    />
                </p>
                {errors.plainPassword && <p className='error-message'>Моля, въведете валидна парола.</p>}
                <p className='control'>
                    <label>Потвърди парола:</label>
                    <input {...register("confirm_password", { required: true, validate: (value) => {
                                                                                const { password } = getValues();
                                                                                return password === value || "Паролите не съвпадат";}}
                                        )
                        }
                        className={errors.confirm_password ? "error-input" : ""}
                        id="confirm_password"
                        type="password"
                    />
                </p>
                {errors.confirm_password?.type === 'required' && <p className='error-message'>Моля, въведете повторно парола.</p>}
                {errors.confirm_password && <p className='error-message'>{errors.confirm_password.message}</p>}
                <div className='action'>
                    <Link to=".." relative="path">
                        <button className='cancel'>Отказ</button>
                    </Link>
                    <button disabled={isSubmitting}>
                        {meta.submitButtonTitle}
                    </button>
                </div>
            </form>
        </section>
    );
}

export default UserForm;