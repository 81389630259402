 import { useLoaderData, useNavigate } from "react-router-dom";
 import { StateMachineProvider, createStore, useStateMachine } from 'little-state-machine';

import DiagnosticsWrapper from "../../components/UI/DiagnosticsWrapper";

const DiagnosticsSheetList = () => {
    const diagnostics = useLoaderData();

    const navigate = useNavigate();

    return (
        <>
        <StateMachineProvider>
            <h1>Списък с диагностики</h1>
            <div className="action"><button onClick={() => {
                                    navigate('/diagnostics/new');
                                }}>Добави новa</button></div>
            <DiagnosticsWrapper data={diagnostics} patientId={null} />
        </StateMachineProvider>
        </>
    );
  }
  
  export default DiagnosticsSheetList;