import { useState, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Api } from '../../utils/api';
import ListWrapper from "./ListWrapper";
import Modal from "./Modal";
import Pagination from "./Pagination";
import DatePicker from 'react-date-picker';
// import SearchContext, { SearchContextProvider } from "../../store/search-context";

import classes from '../../components/UI/ListWrapper.module.css';

let pageSize = 10;

const DiagnosticsWrapper = (props) => {
    //const searchCtx = useContext(SearchContext);
    const [diagnosticsData, setDiagnosticsData] = useState(props.data);
    const [modalIsShown, setModalIsShown] = useState(false);
    const [elemKey, setElemKey] = useState('');
    const [searchDate, onDateChange] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);

    const navigate = useNavigate();

    useMemo(async () => {
        setDiagnosticsData(await Api.getDiagnostics({patientId: props.patientId, date: searchDate}, currentPage, pageSize));
      }, [currentPage, searchDate]);

    const deleteDiagnosticHandler = (id) => {
        Api.deleteDiagnostic(id).then(async (result) => {
            if (result === true) {
                setDiagnosticsData(await Api.getDiagnostics({patientId: props.patientId, date: searchDate}, currentPage, pageSize));
            }
        });
    }

    return (
        <>
        {modalIsShown && 
        <Modal message={'Сигурни ли сте?'} onCancel={() => {setModalIsShown(false);}} onConfirm={() => {deleteDiagnosticHandler(elemKey); setModalIsShown(false);}}></Modal>}
        <section className='lists'>
            <div className="form">
                <label className="lbl-datepicker">Диагностики от дата:</label>
                <DatePicker onChange={onDateChange} locale="bg-BG" format="dd.MM.y" value={searchDate} />
            </div>
            <ListWrapper>
                    {typeof diagnosticsData !== "undefined" ? diagnosticsData.total === 0 ? 
                        <p>Няма въведени диагностики.</p> :
                    <table>
                        <thead>
                            <tr>
                                <th>Дата</th>
                                {!props.patientId && <th>Пациент</th>}
                                <th className={classes.actions}>Редактирай | Изтрий</th>
                            </tr>
                        </thead>
                    <tbody>
                    {diagnosticsData.diagnostics.map((diagnostic) => (
                        <tr key={diagnostic.id}>
                            <td>
                                <Link to={'/diagnostics/' + diagnostic.id}>{diagnostic.dateAdded}</Link>
                            </td>
                            {!props.patientId && 
                            <td>
                                {diagnostic.patient.name}
                            </td>
                            }
                            <td className={classes.actions}>
                                <button className={classes.edit} onClick={() => {
                                    navigate('/diagnostics/' + diagnostic.id + '/edit');
                                }}></button>
                                <button className={classes.delete} onClick={() => {
                                    setModalIsShown(true);
                                    setElemKey(diagnostic.id);
                                }}></button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                : ''}
            </ListWrapper>
            {typeof diagnosticsData !== "undefined" ?
            <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={diagnosticsData.total}
                pageSize={pageSize}
                onPageChange={page => setCurrentPage(page)}
            />
            : ''}
        </section>
        </>
    );
  }
  
  export default DiagnosticsWrapper;