import { useContext } from 'react';
import AuthContext from '../../store/auth-context';
import AuthForm from './AuthForm';

const AuthPage = () => {

  const authCtx = useContext(AuthContext);

  return (
    authCtx.isLoggedIn ? <p>Вече сте влезли в системата</p> : <AuthForm />
  );
};

export default AuthPage;