import classes from './Modal.module.css';

const Backdrop = (props) => {
    return <div className={classes.backdrop} />
};

const ModalOverlay = (props) => {
    return (
        <div className={classes.modal}>
            <div className={classes.content}>
                {props.children}
            </div>
        </div>
    );
};

const Modal = (props) => {
    return (
        <>
            <Backdrop />
            <ModalOverlay>
                <p>{props.message}</p>
                <button className={classes.cancel} onClick={props.onCancel}>Отказ</button>
                <button onClick={props.onConfirm}>Да</button>   
            </ModalOverlay> 
        </>
    );
};

export default Modal;