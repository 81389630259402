import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { checkIsLoggedIn, checkUser } from './store/auth-context';

import UserProfile from './pages/Profile/UserProfile';
import AuthPage from './pages/Login/AuthPage';

import NotFound from './pages/NotFound';
import RootLayout from './pages/Root';
import ProtectedLayout from './pages/Protected';
import DiagnosticsSheetList from './pages/Diagnostics/DiagnosticsSheetList';
import DiagnosticsSheetDetails from './pages/Diagnostics/DiagnosticsSheetDetails';
import PatientsList from './pages/Patients/PatientsList';
import PatientDetails from './pages/Patients/PatientDetails';
import { Api, userRoles } from './utils/api';
import PatientForm from './pages/Patients/PatientForm';
import UsersList from './pages/Users/UsersList';
import UserDetails from './pages/Users/UserDetails';
import UserForm from './pages/Users/UserForm';
import DiagnosticsForm from './pages/Diagnostics/DiagnosticsForm';
import CompaniesList from './pages/Companies/CompaniesList';
import CompanyDetails from './pages/Companies/CompanyDetails';
import CompanyForm from './pages/Companies/CompanyForm';

const router = createBrowserRouter([
  { path: '/', 
    element: <RootLayout />,
    errorElement: <NotFound />,
    children: [
    { path: 'auth', element: <AuthPage />},
    { path: '/', 
    element: <ProtectedLayout />,
    children: [
      { 
        index: true,
        element: <DiagnosticsSheetList />
      },
      { 
        path: 'profile', 
        element: <UserProfile />
      },
      { 
        path: 'companies',
        id: 'companies',
        loader: () => {return checkUser([userRoles.superAdmin]);},
        children: [
          {
            index: true,
            element: <CompaniesList />,
            loader: async () => {
              return await Api.getCompanies('', 1, 10);
            }
          },
          { path: ':companyId',
            id: 'company-details',
            element: <CompanyDetails />,
            loader: async ({ params }) => {
              return await Api.getCompanyDetails(params.companyId);
            },
          },
          { path: ':companyId/edit',
            element: <CompanyForm />,
            loader: async ({ params }) => {
              return {company: await Api.getCompanyDetails(params.companyId), meta: {title: 'Редактиране на фирма', submitButtonTitle: 'Запази'}};
            }
          },
          { 
            path: 'new', 
            element: <CompanyForm />,
            loader: () => {
              return {company: {id: '', name: '', description: '', logo: ''}, meta: {title: 'Нова фирма', submitButtonTitle: 'Добави'}};
            }
          }
        ],
      },
      { 
        path: 'users',
        id: 'users',
        loader: () => {return checkUser([userRoles.superAdmin, userRoles.admin]);},
        children: [
          {
            index: true,
            element: <UsersList />,
            loader: async () => {
              return await Api.getUsers('', 1, 10);
            }
          },
          { path: ':userId',
            id: 'user-details',
            element: <UserDetails />,
            loader: async ({ params }) => {
              return await Api.getUserDetails(params.userId);
            },
          },
          { path: ':userId/edit',
            element: <UserForm />,
            loader: async ({ params }) => {
              return {user: await Api.getUserDetails(params.userId), meta: {title: 'Редактиране на потребител', submitButtonTitle: 'Запази', action: 'edit'}};
            }
          },
          { 
            path: 'new', 
            element: <UserForm />,
            loader: () => {
              return {user: {id: '', role: '', name: '', family: '', email: '', password: '', dateAdded: '', lastModified: '', company: ''}, meta: {title: 'Нов потребител', submitButtonTitle: 'Добави', action: 'new'}};
            }
          }
        ],
      },
      { 
        path: 'patients',
        id: 'patients',
        children: [
          {
            index: true,
            element: <PatientsList />,
            loader: async () => {
              return await Api.getPatients('', 1, 10);
            }
          },
          { path: ':patientId',
            id: 'patient-details',
            element: <PatientDetails />,
            loader: async ({ params }) => {
              return {patients: await Api.getPatientDetails(params.patientId), diagnostics: await Api.getDiagnostics({patientId: params.patientId}, 1, 10)};
            },
          },
          { path: ':patientId/edit',
            element: <PatientForm />,
            loader: async ({ params }) => {
              return {patient: await Api.getPatientDetails(params.patientId), meta: {title: 'Редактиране на пациент', submitButtonTitle: 'Запази'}};
            }
          },
          { 
            path: 'new', 
            element: <PatientForm />,
            loader: () => {
              return {patient: {id: '', name: '', family: '', jobTitle: '', sex: '', age: '', dateAdded: '', lastModified: ''}, meta: {title: 'Нов пациент', submitButtonTitle: 'Добави'}};
            }
          }
        ],
      },

      { 
        path: 'diagnostics',
        id: 'diagnostics',
        children: [
          {
            index: true,
            element: <DiagnosticsSheetList />,
            loader: async () => {
              return await Api.getDiagnostics({}, 1, 10);
            }
          },
          { path: ':sheetId',
            id: 'diagnostics-details',
            element: <DiagnosticsSheetDetails />,
            loader: async ({ params }) => {
              return await Api.getDiagnosticDetails(params.sheetId);
            },
          },
          { path: ':sheetId/edit',
            id: 'diagnostics-form',
            element: <DiagnosticsForm />,
            loader: async ({ params }) => {
              return {diagnostic: await Api.getDiagnosticDetails(params.sheetId), meta: {title: 'Редактиране на диагностика', action: 'edit'}};
            },
          },
          { 
            path: 'new', 
            element: <DiagnosticsForm />,
            loader: () => {
              return {diagnostic: {
                  id: '',
                  dateAdded: new Date(),
                  dateModified: new Date(),
                  patient: {
                    id: '', 
                    name: '',
                    age: null,
                    jobTitle: '',
                    sex: '',
                  },
                  vitals: {
                    height: null,
                    weight: null,
                    bloodPressure: '',
                    pulse: null,
                    saturation: null,
                    medHistory: ''
                  },
                  blood: [
                    {label: 'Mg', result: '', units: '', conclusion: ''},
                    {label: 'B12', result: '', units: '', conclusion: ''},
                    {label: 'Ca', result: '', units: '', conclusion: ''},
                    {label: 'D3', result: '', units: '', conclusion: ''},
                  ],
                  caliper: {
                    subscapula: null,
                    biceps: null,
                    triceps: null,
                    nadialic: null,
                  },
                  bodyScales: {
                    fat: null,
                    hydration: null,
                    muscles: null,
                    bones: null,
                    basalMetabolism: null,
                    activeMetabolism: null,
                  },
                  feet: {
                    image:'',
                    recommendations: '',
                    varus:'',
                    supPro: {left: '', right: ''},
                    left: [],
                    right: [],
                  },
                  spine: {
                    neck: {
                      flexion: null,
                      extension: null,
                      lateral: {left: null, right: null},
                      rotation: {left: null, right: null},
                    },
                    curvature: [],
                    cervical: '',
                    grip: {left: null, right: null},
                    thoracic: {curv: [], degrees: null, direction: ''},
                    lumbar: {lord: '', curv: [], degrees: null, direction: ''},
                    lateral: {left: null, right: null},
                    c7s1: null,
                    th12: null,
                    scoliometerNote: ''
                  },
                  goniometry: {
                    hip: {
                      left: {extension: null, flexion: null, flexionStrKnee: null, abduction: null, adduction: null, rotationInner: null, rotationOuter: null},
                      right: {extension: null, flexion: null, flexionStrKnee: null, abduction: null, adduction:null, rotationInner: null, rotationOuter: null},
                    },
                    shoulder: {
                      left: {extension: null, flexion: null, abduction: null, adduction: null, rotationInner: null, rotationOuter: null},
                      right: {extension: null, flexion: null, abduction: null, adduction: null, rotationInner: null, rotationOuter: null},
                    },
                    ankle: {
                      left: {extension: null, flexion: null},
                      right: {extension: null, flexion: null},
                    },
                    knee: {
                      left: {extension: null, flexion: null},
                      right: {extension: null, flexion: null},
                    },
                    elbow: {
                      left: {extension: null, flexion: null, supination: null, pronation: null},
                      right: {extension: null, flexion: null, supination: null, pronation: null},
                    },
                    wrist: {
                      left: {extension: null, flexion: null, radial: null, ulnar: null},
                      right: {extension: null, flexion: null, radial: null, ulnar: null},
                    },
                  },
                  elasticity: {
                    abdomen: '',
                  },
                  centimetrics: {
                    hip: {left: null, right:null},
                    shank: {left: null, right:null},
                    shoulders: null,
                    neck: null,
                    abdomen: null,
                    chest: null,
                    biceps: {left: null, right:null},
                    forearm: {left: null, right:null},
                  },
                  boneLength: {
                    femur: {left: null, right: null},
                    tibia: {left: null, right: null},
                    humerus: {left: null, right: null},
                    radius: {left: null, right: null},
                  }
            },  meta: {title: 'Нова диагностика', action: 'new'}};
            },
          }
        ],
      },
    ],
    loader: checkIsLoggedIn
  }
  ]
  }
]);


function App() {
  return (
    <RouterProvider router={router} />
  );
};

export default App;
