import { useState, useMemo } from "react";
import { Link, useLoaderData, useNavigate, useRouteLoaderData } from "react-router-dom";
import { useDebounce } from "use-debounce";
import ListWrapper from "../../components/UI/ListWrapper";
import Modal from "../../components/UI/Modal";
import SearchBox from "../../components/UI/SearchBox";
import { Api } from '../../utils/api';
import Pagination from "../../components/UI/Pagination";

import classes from '../../components/UI/ListWrapper.module.css';

let pageSize = process.env.REACT_APP_PAGESIZE;

const CompaniesList = () => {
    const checkedUser = useRouteLoaderData('users');
    const [companiesData, setCompaniesData] = useState(useLoaderData());
    const [modalIsShown, setModalIsShown] = useState(false);
    const [elemKey, setElemKey] = useState('');
    const [searchName, setSearchName] = useState('');
    const [debouncedText] = useDebounce(searchName, 800);

    const [currentPage, setCurrentPage] = useState(1);

    const navigate = useNavigate();

    const companiesDataFunc = async () => {
        const filter = {};

        if (debouncedText && debouncedText !== '') {
            filter.name = debouncedText;
        }

        setCompaniesData(await Api.getCompanies(filter, currentPage, pageSize));
    }

    useMemo(async () => {
        companiesDataFunc();
      }, [currentPage, debouncedText]);

    const deleteCompanyHandler = (id) => {
        Api.deleteCompany(id).then((result) => {
            if (result === true) {
                companiesDataFunc();
            }
        }).catch(errors => {
            console.log(errors);
            //setServerErrors(errors.errors);
          });
        
        //setServerErrors([]);;
    }

    const searchBoxHandler = async (event) => {
        setSearchName(event.target.value);
        setCurrentPage(1);
        companiesDataFunc();
    };

    const clearFilterHandler = async () => {
        setSearchName('');
        setCurrentPage(1);
        companiesDataFunc();
    };

    return (
        <>
        {modalIsShown && 
        <Modal message={'Сигурни ли сте?'} onCancel={() => {setModalIsShown(false);}} onConfirm={() => {deleteCompanyHandler(elemKey); setModalIsShown(false);}}></Modal>}
        <section className='lists'>
            <h1>Фирми</h1>
            <SearchBox>
             <div className='filter-wrapper'>
                <div className='control'>
                    <label htmlFor=''>Филтрирай по име:</label>
                    <input type='search' name={searchName} value={searchName} onChange={searchBoxHandler} />
                </div>
                {/* {checkedUser === userRoles.superAdmin &&} */}
                <div className="action"><button className="cancel" onClick={clearFilterHandler}>Изчисти</button></div>
             </div>
            </SearchBox>
            <div className="action"><button onClick={() => {
                                    navigate('/companies/new');
                                }}>Добави нова</button></div>
            <ListWrapper>
                {companiesData.total === 0 ? 
                    <p>Не се откриват фирми по зададените критерии.</p> :
                    <table>
                        <thead>
                            <tr>
                                {/* <th>Лого</th> */}
                                <th>Фирма</th>
                                <th>Описание</th>
                                <th className={classes.actions}>Редактирай | Изтрий</th>
                            </tr>
                        </thead>
                        <tbody>
                        {companiesData.companies.map((company) => (
                            <tr key={company.id}>
                                {/* <td>{company.logo && <Link to={company.id}><img src={company.logo} alt="logo" className="clogo-thumb" /></Link>}</td> */}
                                <td><Link to={String(company.id)}>{company.name}</Link></td>
                                <td className="cdesc">
                                {company.description && company.description.length > 35 ?
                                    `${company.description.substring(0, 35)}...` : company.description
                                }
                                </td>
                                <td className={classes.actions}>
                                    <button className={classes.edit} onClick={() => {
                                        navigate(company.id + '/edit');
                                    }}></button>
                                    <button className={classes.delete} onClick={() => {
                                        setModalIsShown(true);
                                        setElemKey(company.id);
                                    }}></button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                }
            </ListWrapper>
            <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={companiesData.total}
                pageSize={pageSize}
                onPageChange={page => setCurrentPage(page)}
            />
        </section>
        </>
    );
  }
  
  export default CompaniesList;