import React from 'react';
import PersonalCard from "../../components/UI/PersonalCard";

import FeetModel from "../../models/Feet";
import ElasticityModel from "../../models/Elasticity";
import SpineModel from '../../models/Spine';
import CurvModel from '../../models/Curv';
import CervicalModel from '../../models/Cervical';
import LumbarModel from '../../models/Lumbar';
import { goniometryEvaluation, goniometryLeftRight, goniometryLeftRightSingleRef, musclesEvaluation, musclesEvaluationLatRot, musclesEvaluationSingle } from '../../utils/utils';

import neck from './Images/neck.jpg';
import femur from './Images/femur.jpg';
import tibia from './Images/tibia.jpg';
import humerus from './Images/humerus.jpg';
import radius from './Images/radius.jpg';
import hip from './Images/hip.jpg';
import shank from './Images/shank.jpg';
import chest from './Images/chest.jpg';
import abdomen from './Images/abdomen.jpg';
import shoulders from './Images/shoulders.jpg';
import biceps from './Images/biceps.jpg';
import forearm from './Images/forearm.jpg';
import neck_measurement from './Images/neck_measurement.jpg';
import musclesFront from './Images/muscles_front.jpg';
import musclesBack from './Images/muscles_back.jpg';
import varusNormal from './Images/varus_normal.jpg';
import varusBow from './Images/varus_bow.jpg';
import varusKnock from './Images/varus_knock.jpg';
import supProNeutral from './Images/neutral.jpg';
import supProSupination from './Images/supination.jpg';
import supProOverSupination from './Images/oversupination.jpg';
import supProPronation from './Images/pronation.jpg';
import supProOverPronation from './Images/overpronation.jpg';
import spineHLLKLC from './Images/spine_hyper_lord_lumb_kyph_lord_cerv.jpg';
import spineHLLK from './Images/spine_hyper_lord_lumb_kyph.jpg';
import spineHLL from './Images/spine_hyper_lord_lumb.jpg';
import spineK from './Images/spine_kyph.jpg';
import spineLCFLL from './Images/spine_lord_cerv_flatback_lord_lumb.jpg';
import spineLCHLL from './Images/spine_lord_cerv_hyper_lord_lumb.jpg';
import spineLCK from './Images/spine_lord_cerv_kyph.jpg';
import spineLCLL from './Images/spine_lord_cerv_lord_lumb.jpg';
import spineLC from './Images/spine_lord_cerv.jpg';
import spineLLKLC from './Images/spine_lord_lumb_kyph_lord_cerv.jpg';
import spineLLK from './Images/spine_lord_lumb_kyph.jpg';
import spineLL from './Images/spine_lord_lumb.jpg';
import spineN from './Images/spine_norm.jpg';
import spineSLLTL from './Images/spine_scol_lumb_left_thor_left.jpg';
import spineSLLTR from './Images/spine_scol_lumb_left_thor_right.jpg';
import spineSLL from './Images/spine_scol_lumb_left.jpg';
import spineSLRTL from './Images/spine_scol_lumb_right_thor_left.jpg';
import spineSLRTR from './Images/spine_scol_lumb_right_thor_right.jpg';
import spineSLR from './Images/spine_scol_lumb_right.jpg';
import spineSTL from './Images/spine_scol_thor_left.jpg';
import spineSTR from './Images/spine_scol_thor_right.jpg';
import jointsAnkle from './Images/joints_ankle.jpg';
import jointsElbow from './Images/joints_elbow.jpg';
import jointsElbow1 from './Images/joints_elbow1.jpg';
import jointsHip from './Images/joints_hip.jpg';
import jointsHip1 from './Images/joints_hip1.jpg';
import jointsHip2 from './Images/joints_hip2.jpg';
import jointsKnee from './Images/joints_knee.jpg';
import jointsShoulder from './Images/joints_shoulder.jpg';
import jointsShoulder1 from './Images/joints_shoulder1.jpg';
import jointsShoulder2 from './Images/joints_shoulder2.jpg';
import jointsWrist from './Images/joints_wrist.jpg';
import jointsWrist1 from './Images/joints_wrist1.jpg';
import fat from './Images/fat.png';
import muscles from './Images/muscles.png';
import hydration from './Images/hydration.png';
import bones from './Images/bones.png';
import activeMetabolism from './Images/active_metabolism.png';
import basalMetabolism from './Images/basal_metabolism.png';
import pin from './Images/pin.png';

const spineImages = {
    '1': spineN,
    '2': spineSLL,
    '3': spineSLR,
    '4': spineSTL,
    '5': spineSTR,
    '6': spineSLRTL,
    '7': spineSLLTR,
    '8': spineSLLTL,
    '9': spineSLRTR,
    '10': spineK,
    '11': spineLL,
    '12': spineLLK,
    '13': spineLLKLC,
    '14': spineHLL,
    '15': spineHLLK,
    '16': spineHLLKLC,
    '17': spineLC,
    '18': spineLCK,
    '19': spineLCHLL,
    '20': spineLCLL,
    '21': spineLCFLL,
}

const DiagnosticsSheetData = React.forwardRef((props, ref) => {

   const cvtDateToString = (date) => {
       if (typeof date === "string") {
           return date;
       } else {
           return `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}.${date.getFullYear()}`;
       }
   }

    return (
        <section className='lists' ref={ref}>
            <div className='centered-print'><img src={props.data.logo} alt="logo" /></div>
            <h1 className='colored'>Физикална и обща диагностика</h1>
            <p className='dates centered-print' style={{margin: '-1.5em auto 2.5em'}}>Добавена на: {cvtDateToString(props.data.values.dateAdded)} г. Последно редактирана на: {cvtDateToString(props.data.values.dateModified)} г.</p>
            <PersonalCard data={{id: props.data.values.patient.id, name: props.data.values.patient.name, age: props.data.values.patient.age, jobTitle: props.data.values.patient.jobTitle, sex: props.data.values.patient.sex}} />
            <h2>Витални показатели</h2>
            <div className='multi-inp wider top'>
                <div className='left half'>
                    <p><label>Кръвно налягане:</label>{props.data.values.vitals.bloodPressure}</p>
                    <p><label>Пулс:</label>{props.data.values.vitals.pulse}</p>
                    <p><label>Сатурация:</label>{props.data.values.vitals.saturation && <>{props.data.values.vitals.saturation} %</>}</p>
                </div>
                <div className='left half'>
                    <p><label>Ръст:</label>{props.data.values.vitals.height && <>{props.data.values.vitals.height} см</>}</p>
                    <p><label>Тегло:</label>{props.data.values.vitals.weight && <>{props.data.values.vitals.weight} кг</>}</p>
                    <p><label>Анамнеза:</label>{props.data.values.vitals.medHistory}</p>
                </div>
            </div>
            <h2>Измерване с калипер</h2>
            <table className='simple-tbl'>
                <thead>
                    <tr>
                        <th>Подскапула</th>
                        <th>Трицепс</th>
                        <th>Бицепс</th>
                        <th>Надиалична</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{props.data.values.caliper.subscapula && <>{props.data.values.caliper.subscapula} мм (10.6 мм)</>}</td>
                        <td>{props.data.values.caliper.triceps && <>{props.data.values.caliper.triceps} мм (12.4 мм)</>}</td>
                        <td>{props.data.values.caliper.biceps && <>{props.data.values.caliper.biceps} мм (5.6 мм)</>}</td>
                        <td>{props.data.values.caliper.nadialic && <>{props.data.values.caliper.nadialic} мм (12 мм)</>}</td>
                    </tr>
                </tbody>
            </table>
            <h2>Лабораторни кръвни изследвания</h2>
            <div className='left'>
                {props.data.values.blood.map((test) => (
                    <p key={test.label}>
                        <label>{test.label}:</label>
                        {test.result && <span className={test.conclusion === 'def' ? 'alert-def' : (test.conclusion === 'close-def' ? 'alert-warn' : (test.conclusion === 'ab' ? 'alert-ab' : 'alert-ok'))}>{test.result} {test.units}</span>}
                    </p>
                ))}
            </div>
            <h2>Метаболизъм</h2>
            <div className='flex-left'>
                <div className='multi-inp'><img src={fat} className='subsection-icon' /><label>Мазнини:</label>{props.data.values.bodyScales.fat && <>{props.data.values.bodyScales.fat} %</>}</div>
                <div className='scale'>
                    {props.data.values.bodyScales.fat && <div style={{position:'absolute', top:'-26px', left:`${(((props.data.values.bodyScales.fat-5)*100)/35).toString()}%`}}><img src={pin} /></div>}
                    <div className='segment blue'>&nbsp;</div><div className='segment green'>&nbsp;</div><div className='segment red'>&nbsp;</div>
                </div>
                <div className='multi-inp'><img src={hydration} className='subsection-icon' /><label>Хидратация:</label>{props.data.values.bodyScales.hydration && <>{props.data.values.bodyScales.hydration} %</>}</div>
                    <div className='scale'>
                        <div style={{position:'absolute', top:'-26px', left:`${100-Math.min((((props.data.values.bodyScales.hydration-45)*100)/25), 100).toString()}%`}}><img src={pin} /></div>
                        <div className='segment blue'>&nbsp;</div><div className='segment green'>&nbsp;</div><div className='segment red'>&nbsp;</div>
                    </div>
                <div className='multi-inp'><img src={muscles} className='subsection-icon' /><label>Мускули:</label>{props.data.values.bodyScales.muscles && <>{props.data.values.bodyScales.muscles} %</>}</div>
                    <div className='scale'>
                        <div style={{position:'absolute', top:'-26px', left:`${100-Math.min((((props.data.values.bodyScales.muscles-55)*100)/35), 100).toString()}%`}}><img src={pin} /></div>
                        <div className='segment blue'>&nbsp;</div><div className='segment green'>&nbsp;</div><div className='segment red'>&nbsp;</div>
                    </div>
                <div className='multi-inp'><img src={bones} className='subsection-icon' /><label>Костна маса:</label>{props.data.values.bodyScales.bones && <>{props.data.values.bodyScales.bones} кг</>}</div>
                <div className='multi-inp'><img src={basalMetabolism} className='subsection-icon' /><label>Базален метаболизъм:</label>{props.data.values.bodyScales.basalMetabolism && <>{props.data.values.bodyScales.basalMetabolism} Kcal</>}</div>
                <div className='multi-inp'><img src={activeMetabolism} className='subsection-icon' /><label>Активен метаболизъм:</label>{props.data.values.bodyScales.activeMetabolism && <>{props.data.values.bodyScales.activeMetabolism} Kcal</>}</div>
            </div>
            <h2>Диагностика на ходилата</h2>
            {props.data.values.feet.image && <div className='centered-print'><img src={props.data.values.feet.image} className='feet-img' alt="feet" /></div>}
            <div className='card-imgs'>
                <div style={{marginRight: '2em'}}>
                    <h3>Ляво ходило:</h3>
                    <img src={supProSupination} className={props.data.values.feet.supPro.left === 'supination' ? 'outlined-img-alert' : ''} /><img src={supProOverSupination} className={props.data.values.feet.supPro.left === 'overSupination' ? 'outlined-img-alert' : ''} /><img src={supProOverPronation} className={props.data.values.feet.supPro.left === 'overPronation' ? 'outlined-img-alert' : ''}/><img src={supProPronation} className={props.data.values.feet.supPro.left === 'pronation' ? 'outlined-img-alert' : ''} /><img src={supProNeutral} className={props.data.values.feet.supPro.left === 'neutral' ? 'outlined-img-ok' : ''} />
                </div>
                <div>
                    <h3>Дясно ходило:</h3>
                    <img src={supProSupination} className={props.data.values.feet.supPro.right === 'supination' ? 'outlined-img-alert' : ''} /><img src={supProOverSupination} className={props.data.values.feet.supPro.right === 'overSupination' ? 'outlined-img-alert' : ''} /><img src={supProOverPronation} className={props.data.values.feet.supPro.right === 'overPronation' ? 'outlined-img-alert' : ''} /><img src={supProPronation} className={props.data.values.feet.supPro.right === 'pronation' ? 'outlined-img-alert' : ''} /><img src={supProNeutral} className={props.data.values.feet.supPro.right === 'neutral' ? 'outlined-img-ok' : ''} />
                </div>
            </div>
            <h3>Варусна деформация</h3>
            <div className='card-imgs'>
                <div>
                    <img src={varusNormal} className={props.data.values.feet.varus === 'normal' ? 'outlined-img-ok' : ''} /><img src={varusBow} className={props.data.values.feet.varus === 'bow' ? 'outlined-img-alert' : ''} /><img src={varusKnock} className={props.data.values.feet.varus === 'knock' ? 'outlined-img-alert' : ''} />
                </div>
            </div>
            <div className='left'>
                <p><label>Ляво ходило:</label> {props.data.values.feet.left.map((feet, index) => 
                    <span key={index}>
                        { (index ? ', ' : '') + FeetModel.getFeetString(feet) }
                    </span>
                )}</p>
                <p><label>Дясно ходило:</label> {props.data.values.feet.right.map((feet, index) => 
                    <span key={index}>
                        { (index ? ', ' : '') + FeetModel.getFeetString(feet) }
                    </span>
                )}</p>
            </div>
            <h3><label>Препоръки:</label></h3>{props.data.values.feet.recommendations}
            <div className="important-info-wrapper">Внимание: Проблемите с ходилата могат да доведат с времето до изкривявания на гръбнака и увреждане на коленете и тазобедрените стави.</div>
            <h2>Диагностика на гръбначен стълб</h2>
            <h3>Ъглометрия шиен дял</h3>
            <img src={neck} className='form-img' />
            <div className='left'>
                <p><label>Флексия (60°-70°):</label> 
                    {props.data.values.spine.neck.flexion && <>{props.data.values.spine.neck.flexion}° {props.data.values.spine.neck.flexion < 60 ? <span className='alert-def'>намален обем на движение</span> : <span className='alert-ok'> в норма</span>}</>}</p>
                <p><label>Екстензия (40°-50°):</label> 
                    {props.data.values.spine.neck.extension && <>{props.data.values.spine.neck.extension}° 
                        {props.data.values.spine.neck.extension < 40 ? <span className='alert-def'>намален обем на движение</span> : 
                        (props.data.values.spine.neck.extension >= 40 && props.data.values.spine.neck.extension <= 50 ? <span className='alert-ok'> в норма</span> : 
                        <span className='alert-ab'>хиперекстензия</span>
                        // (props.data.values.spine.neck.extension > 70 ? <span className='alert-ab'>хиперекстензия</span> : '')
                        )}
                    </>}
                </p>
                <p><label>Латерални наклони (20°-45°):</label> 
                    ляво - {props.data.values.spine.neck.lateral.left}°, дясно - {props.data.values.spine.neck.lateral.right}°
                    {goniometryLeftRight('латерални наклони шиен дял', 'наляво', 'надясно', 20, 45, props.data.values.spine.neck.lateral.left, props.data.values.spine.neck.lateral.right)}
                </p>
                <p><label>Ротация (70°-90°):</label> 
                    ляво - {props.data.values.spine.neck.rotation.left}°, дясно - {props.data.values.spine.neck.rotation.right}°
                    {goniometryLeftRight('ротация шиен дял', 'наляво', 'надясно', 70, 90, props.data.values.spine.neck.rotation.left, props.data.values.spine.neck.rotation.right)}
                </p>
            </div>
            <h3>Общ преглед на гръбначния стълб</h3>
            <div className='card-imgs'>
                    {props.data.values.spine.curvature.map((item, index) => 
                        <div className='spine-img' key={index}>
                            <img src={spineImages[parseInt(item)]} alt={SpineModel.getSpineString(item)} className={item === '1' ? 'outlined-img-ok' : 'outlined-img-alert'} />
                            <span className={item === '1' ? 'alert-ok' : 'alert-not-ok'}>{SpineModel.getSpineString(item)}</span>
                        </div>
                    )}
                    {/* <img src={spineN} className={props.data.values.spine.curvature.includes("1") ? 'outlined-img-ok' : ''} />
                    <img src={spineSLL} className={props.data.values.spine.curvature.includes("2") ? 'outlined-img-alert' : ''} /> */}
            </div>
            <div className='left'>
                <p><label>Шиен дял:</label> {CervicalModel.getCervicalString(props.data.values.spine.cervical)}</p>
                <p><label>Сила на захват:</label> {props.data.values.spine.grip.left && <>ляво - {props.data.values.spine.grip.left} кг,</>} {props.data.values.spine.grip.right && <>дясно - {props.data.values.spine.grip.right} кг</>}</p>
                <p><label>Гръден дял:</label> {props.data.values.spine.thoracic.curv.map((curv, index) => 
                    <span key={index}>
                        { (index ? ', ' : '') + CurvModel.getCurvString(curv)} 
                        {(curv === '1') && (<>{' - ' + String(props.data.values.spine.thoracic.direction) + ' - ' + String(props.data.values.spine.thoracic.degrees) + '°'}</>) }
                    </span>
                )}</p>
                <p><label>Лумбален дял:</label> {LumbarModel.getLumbarString(props.data.values.spine.lumbar.lord)}{props.data.values.spine.lumbar.curv.map((curv, index) => 
                    <span key={index}>
                        { ', ' + CurvModel.getCurvString(curv)} 
                        {(curv === '1') && (<>{' - ' + String(props.data.values.spine.lumbar.direction) + ' - ' + String(props.data.values.spine.lumbar.degrees) + '°'}</>) }
                    </span>
                )}</p>
                <p><label>Латерални наклони (30°):</label> 
                    ляво - {props.data.values.spine.lateral.left}°, дясно - {props.data.values.spine.lateral.right}°
                    {goniometryLeftRightSingleRef('латерални наклони гръбначен стълб', 'наляво', 'надясно', 30, props.data.values.spine.lateral.left, props.data.values.spine.lateral.right)}
                </p>
                <p><label>Сакрален дял:</label> 
                    {props.data.values.spine.lumbar.curv.length === 0 ? '' : !props.data.values.spine.lumbar.curv.includes('1') ? <span>няма изменение</span> : (props.data.values.spine.lumbar.direction === 'ляво' ? <span>тазът се усуква наляво</span> : <span>тазът се усуква надясно</span>)}
                </p>
            </div>
            <div>
                <h4>Измерване на От(C7-S1) 10см (Измерване подвижността на гръбначния стълб):</h4>{props.data.values.spine.c7s1 && <>След направено измерване на От е установен {props.data.values.spine.c7s1 === 10 ? 'нормален' : (props.data.values.spine.c7s1 < 10 && props.data.values.spine.c7s1 > 9 ? 'намален' : 'ограничен')} обем на движение на гръбначен стълб - {props.data.values.spine.c7s1} см</>}
                <h4>Измерване на От(Th12) 7.5см (Измерване подвижността на гръбначния стълб):</h4>{props.data.values.spine.th12 && <>След направено измерване на От е установен {props.data.values.spine.th12 === 7.5 ? 'нормален' : (props.data.values.spine.th12 < 7.5 && props.data.values.spine.th12 > 6 ? 'намален' : 'ограничен')} обем на движение на гръбначен стълб - {props.data.values.spine.th12} см</>}
            </div>
            {/* <h3><label>Препоръки:</label></h3>{props.data.values.spine.recommendations} */}
            <div className="important-info-wrapper">Измерванията за сколиоза са извършени със сколиометър. <br />{props.data.values.spine.scoliometerNote}</div>
            <h2>Ъглометрия - подвижност на стави</h2>
            <h3>Тазобедрена става</h3>
            <div className='multi-inp wider top'>
                <div className='wide-col'>
                    <h4>Екстензия-Флексия (15°-0°-125°):</h4>
                    <div className='multi-inp'>
                        <p>
                            <label>Лява:</label>
                            {props.data.values.goniometry.hip.left.extension}° -0°- {props.data.values.goniometry.hip.left.flexion}°<br />
                            {goniometryEvaluation('тазобедрена става', 'екстензия', 'флексия', 15, 125, props.data.values.goniometry.hip.left.extension, props.data.values.goniometry.hip.left.flexion)}
                            {props.data.values.goniometry.hip.left.extension > 15 && <span className='error-message'>{`, ${props.data.values.goniometry.hip.left.extension - 15}° хиперекстензия`}</span>}<br />
                            флексия с изпънато коляно: {props.data.values.goniometry.hip.left.flexionStrKnee}° (90°) <br />{props.data.values.goniometry.hip.left.flexionStrKnee && (props.data.values.goniometry.hip.left.flexionStrKnee < 90 ? <span className='alert-def'>намален обем на движение</span> : <span className='alert-ok'> в норма</span>)}<br /><br />
                            <label>Дясна:</label>{props.data.values.goniometry.hip.right.extension}° -0°- {props.data.values.goniometry.hip.right.flexion}°<br />
                            {goniometryEvaluation('тазобедрена става', 'екстензия', 'флексия', 15, 125, props.data.values.goniometry.hip.right.extension, props.data.values.goniometry.hip.right.flexion)}<br />
                            {props.data.values.goniometry.hip.right.extension > 15 && <span className='error-message'>{`, ${props.data.values.goniometry.hip.right.extension - 15}° хиперекстензия`}</span>}
                            флексия с изпънато коляно: {props.data.values.goniometry.hip.right.flexionStrKnee}° (90°) <br />{props.data.values.goniometry.hip.right.flexionStrKnee && (props.data.values.goniometry.hip.right.flexionStrKnee < 90 ? <span className='alert-def'>намален обем на движение</span> : <span className='alert-ok'> в норма</span>)}
                        </p>
                    </div>
                    <h4>Абдукция-Аддукция (45°-0°-30°):</h4>
                    <div className='multi-inp'>
                        <p>
                            <label>Лява:</label>{props.data.values.goniometry.hip.left.abduction}° -0°- {props.data.values.goniometry.hip.left.adduction}° <br />
                            {goniometryEvaluation('тазобедрена става', 'абдукция', 'аддукция', 45, 30, props.data.values.goniometry.hip.left.abduction, props.data.values.goniometry.hip.left.adduction)}<br /><br />
                            <label>Дясна:</label>{props.data.values.goniometry.hip.right.abduction}° -0°- {props.data.values.goniometry.hip.right.adduction}°<br />
                            {goniometryEvaluation('тазобедрена става', 'абдукция', 'аддукция', 45, 30, props.data.values.goniometry.hip.right.abduction, props.data.values.goniometry.hip.right.adduction)}
                        </p>
                    </div>
                    <h4>Вътрешна-Външна Ротация (45°-0°-45°):</h4>
                    <div className='multi-inp'>
                        <p>
                            <label>Лява:</label>{props.data.values.goniometry.hip.left.rotationInner}° -0°- {props.data.values.goniometry.hip.left.rotationOuter}°<br />
                            {goniometryEvaluation('тазобедрена става', 'вътрешна ротация', 'външна ротация', 45, 45, props.data.values.goniometry.hip.left.rotationInner, props.data.values.goniometry.hip.left.rotationOuter)}<br /><br />
                            <label>Дясна:</label>{props.data.values.goniometry.hip.right.rotationInner}° -0°- {props.data.values.goniometry.hip.right.rotationOuter}°<br />
                            {goniometryEvaluation('тазобедрена става', 'вътрешна ротация', 'външна ротация', 45, 45, props.data.values.goniometry.hip.right.rotationInner, props.data.values.goniometry.hip.right.rotationOuter)}
                        </p>
                    </div>
                </div>
                <div className='right-col-imgs'>
                    <div><img src={jointsHip} className="goniometry-img"/></div>
                    <div><img src={jointsHip1} className="goniometry-img" /></div>
                    <div><img src={jointsHip2} className="goniometry-img" /></div>
                </div>
            </div>
            <h3>Раменна става</h3>
            <div className='multi-inp wider top'>
                <div className='wide-col'>
                    <h4>Екстензия-Флексия (60°-0°-180°):</h4>
                    <div className='multi-inp'>
                        <p>
                            <label>Лява:</label>{props.data.values.goniometry.shoulder.left.extension}° -0°- {props.data.values.goniometry.shoulder.left.flexion}°<br />
                            {goniometryEvaluation('раменна става', 'екстензия', 'флексия', 60, 180, props.data.values.goniometry.shoulder.left.extension, props.data.values.goniometry.shoulder.left.flexion)}
                            {props.data.values.goniometry.shoulder.left.extension > 60 && <span className='error-message'>{`, ${props.data.values.goniometry.shoulder.left.extension - 60}° хиперекстензия`}</span>}<br /><br />
                            <label>Дясна:</label>{props.data.values.goniometry.shoulder.right.extension}° -0°- {props.data.values.goniometry.shoulder.right.flexion}°<br />
                            {goniometryEvaluation('раменна става', 'екстензия', 'флексия', 60, 180, props.data.values.goniometry.shoulder.right.extension, props.data.values.goniometry.shoulder.right.flexion)}
                            {props.data.values.goniometry.shoulder.right.extension > 60 && <span className='error-message'>{`, ${props.data.values.goniometry.shoulder.right.extension - 60}° хиперекстензия`}</span>}
                        </p>
                    </div>
                    <h4>Абдукция-Аддукция (180°-0°-0°):</h4>
                    <div className='multi-inp'>
                        <p>
                            <label>Лява:</label>{props.data.values.goniometry.shoulder.left.abduction}° -0°- {props.data.values.goniometry.shoulder.left.adduction}°<br />
                            {goniometryEvaluation('раменна става', 'абдукция', 'аддукция', 180, 0, props.data.values.goniometry.shoulder.left.abduction, props.data.values.goniometry.shoulder.left.adduction)}<br /><br />
                            <label>Дясна:</label>{props.data.values.goniometry.shoulder.right.abduction}° -0°- {props.data.values.goniometry.shoulder.right.adduction}°<br />
                            {goniometryEvaluation('раменна става', 'абдукция', 'аддукция', 180, 0, props.data.values.goniometry.shoulder.right.abduction, props.data.values.goniometry.shoulder.right.adduction)}
                        </p>
                    </div>
                    <h4>Вътрешна-Външна Ротация (90°-0°-90°):</h4>
                    <div className='multi-inp'>
                        <p>
                            <label>Лява:</label>{props.data.values.goniometry.shoulder.left.rotationInner}° -0°- {props.data.values.goniometry.shoulder.left.rotationOuter}°<br />
                            {goniometryEvaluation('раменна става', 'вътрешна ротация', 'външна ротация', 90, 90, props.data.values.goniometry.shoulder.left.rotationInner, props.data.values.goniometry.shoulder.left.rotationOuter)}<br /><br />
                            <label>Дясна:</label>{props.data.values.goniometry.shoulder.right.rotationInner}° -0°- {props.data.values.goniometry.shoulder.right.rotationOuter}°<br />
                            {goniometryEvaluation('раменна става', 'вътрешна ротация', 'външна ротация', 90, 90, props.data.values.goniometry.shoulder.right.rotationInner, props.data.values.goniometry.shoulder.right.rotationOuter)}
                        </p>
                    </div>
                </div>
                <div className='right-col-imgs'>
                    <div><img src={jointsShoulder} className="goniometry-img"/></div>
                    <div><img src={jointsShoulder1} className="goniometry-img" /></div>
                    <div><img src={jointsShoulder2} className="goniometry-img" /></div>
                </div>
            </div>
            <h3>Глезенна става</h3>
            <div className='control multi-inp wider top'>
                <div className='wide-col'>
                    <h4>Флексия-Екстензия (26°-0°-48°):</h4>
                    <div className='multi-inp'>
                        <p>
                            <label>Лява:</label>{props.data.values.goniometry.ankle.left.flexion}° -0°- {props.data.values.goniometry.ankle.left.extension}°<br />
                            {goniometryEvaluation('глезенна става', 'екстензия', 'флексия', 48, 26, props.data.values.goniometry.ankle.left.extension, props.data.values.goniometry.ankle.left.flexion)}
                            {props.data.values.goniometry.ankle.left.extension > 48 && <span className='error-message'>{`, ${props.data.values.goniometry.ankle.left.extension - 48}° хиперекстензия`}</span>}<br /><br />
                            <label>Дясна:</label>{props.data.values.goniometry.ankle.right.flexion}° -0°- {props.data.values.goniometry.ankle.right.extension}°<br />
                            {goniometryEvaluation('глезенна става', 'екстензия', 'флексия', 48, 26, props.data.values.goniometry.ankle.right.extension, props.data.values.goniometry.ankle.right.flexion)}
                            {props.data.values.goniometry.ankle.right.extension > 48 && <span className='error-message'>{`, ${props.data.values.goniometry.ankle.right.extension - 48}° хиперекстензия`}</span>}
                        </p>
                    </div>
                </div>
                <div className='right-col-imgs'>
                    <div><img src={jointsAnkle} className="goniometry-img"/></div>
                </div>
            </div>
            <h3>Колянна става</h3>
            <div className='multi-inp wider top'>
                <div className='wide-col'>
                    <h4>Екстензия-Флексия (0°-0°-130°):</h4>
                    <div className='multi-inp'>
                        <p>
                            <label>Лява:</label>{props.data.values.goniometry.knee.left.extension}° -0°- {props.data.values.goniometry.knee.left.flexion}°<br />
                            {goniometryEvaluation('колянна става', 'екстензия', 'флексия', 0, 130, props.data.values.goniometry.knee.left.extension, props.data.values.goniometry.knee.left.flexion)}
                            {props.data.values.goniometry.knee.left.extension > 0 && <span className='error-message'>{`, ${props.data.values.goniometry.knee.left.extension}° хиперекстензия`}</span>}<br /><br />
                            <label>Дясна:</label>{props.data.values.goniometry.knee.right.extension}° -0°- {props.data.values.goniometry.knee.right.flexion}°<br />
                            {goniometryEvaluation('колянна става', 'екстензия', 'флексия', 0, 130, props.data.values.goniometry.knee.right.extension, props.data.values.goniometry.knee.right.flexion)}
                            {props.data.values.goniometry.knee.right.extension > 0 && <span className='error-message'>{`, ${props.data.values.goniometry.knee.right.extension}° хиперекстензия`}</span>}
                        </p>
                    </div>
                </div>
                <div className='right-col-imgs'>
                    <div><img src={jointsKnee} className="goniometry-img"/></div>
                </div>
            </div>
            <h3>Лакътна става</h3>
            <div className='multi-inp wider top'>
                <div className='wide-col'>
                    <h4>Екстензия-Флексия (0°-0°-145°):</h4>
                    <div className='multi-inp'>
                        <p>
                            <label>Лява:</label>{props.data.values.goniometry.elbow.left.extension}° -0°- {props.data.values.goniometry.elbow.left.flexion}°<br />
                            {goniometryEvaluation('лакътна става', 'екстензия', 'флексия', 0, 145, props.data.values.goniometry.elbow.left.extension, props.data.values.goniometry.elbow.left.flexion)}
                            {props.data.values.goniometry.elbow.left.extension >= 15 && <span className='error-message'>{`, ${props.data.values.goniometry.elbow.left.extension}° хиперекстензия`}</span>}<br /><br />
                            <label>Дясна:</label>{props.data.values.goniometry.elbow.right.extension}° -0°- {props.data.values.goniometry.elbow.right.flexion}°<br />
                            {goniometryEvaluation('лакътна става', 'екстензия', 'флексия', 0, 145, props.data.values.goniometry.elbow.right.extension, props.data.values.goniometry.elbow.right.flexion)}
                            {props.data.values.goniometry.elbow.right.extension >= 15 && <span className='error-message'>{`, ${props.data.values.goniometry.elbow.right.extension}° хиперекстензия`}</span>}
                        </p>
                    </div>
                    <h4>Супинация-Пронация (90°-0°-90°):</h4>
                    <div className='multi-inp'>
                        <p>
                            <label>Лява:</label>{props.data.values.goniometry.elbow.left.supination}° -0°- {props.data.values.goniometry.elbow.left.pronation}°<br />
                            {goniometryEvaluation('лакътна става', 'супинация', 'пронация', 90, 90, props.data.values.goniometry.elbow.left.supination, props.data.values.goniometry.elbow.left.pronation)}<br /><br />
                            <label>Дясна:</label>{props.data.values.goniometry.elbow.right.supination}° -0°- {props.data.values.goniometry.elbow.right.pronation}°<br />
                            {goniometryEvaluation('лакътна става', 'супинация', 'пронация', 90, 90, props.data.values.goniometry.elbow.right.supination, props.data.values.goniometry.elbow.right.pronation)}
                        </p>
                    </div>
                </div>
                <div className='right-col-imgs'>
                    <div><img src={jointsElbow} className="goniometry-img"/></div>
                    <div><img src={jointsElbow1} className="goniometry-img"/></div>
                </div>
            </div>
            <h3>Става на китката</h3>
            <div className='multi-inp wider top'>
                <div className='wide-col'>
                    <h4>Екстензия-Флексия (70°-0°-90°):</h4>
                    <div className='multi-inp'>
                        <p>
                            <label>Лява:</label>{props.data.values.goniometry.wrist.left.extension}° -0°- {props.data.values.goniometry.wrist.left.flexion}°<br />
                            {goniometryEvaluation('става на китката', 'екстензия', 'флексия', 70, 90, props.data.values.goniometry.wrist.left.extension, props.data.values.goniometry.wrist.left.flexion)}<br /><br />
                            <label>Дясна:</label>{props.data.values.goniometry.wrist.right.extension}° -0°- {props.data.values.goniometry.wrist.right.flexion}°<br />
                            {goniometryEvaluation('става на китката', 'екстензия', 'флексия', 70, 90, props.data.values.goniometry.wrist.right.extension, props.data.values.goniometry.wrist.right.flexion)}
                        </p>
                    </div>
                    <h4>Радиално-Улнарно отвеждане (20°-0°-40°):</h4>
                    <div className='multi-inp'>
                        <p>
                            <label>Лява:</label>{props.data.values.goniometry.wrist.left.radial}° -0°- {props.data.values.goniometry.wrist.left.ulnar}°<br />
                            {goniometryEvaluation('става на китката', 'радиално отвеждане', 'улнарно отвеждане', 20, 40, props.data.values.goniometry.wrist.left.radial, props.data.values.goniometry.wrist.left.ulnar)}<br /><br />
                            <label>Дясна:</label>{props.data.values.goniometry.wrist.right.radial}° -0°- {props.data.values.goniometry.wrist.right.ulnar}°<br />
                            {goniometryEvaluation('става на китката', 'радиално отвеждане', 'улнарно отвеждане', 20, 40, props.data.values.goniometry.wrist.right.radial, props.data.values.goniometry.wrist.right.ulnar)}
                        </p>
                    </div>
                </div>
                <div className='right-col-imgs'>
                    <div><img src={jointsWrist} className="goniometry-img"/></div>
                    <div><img src={jointsWrist1} className="goniometry-img"/></div>
                </div>
            </div>
            <h2>Оценка за еластичност на основни сухожилия и мускули</h2>
            <div className='multi-inp wider top'>
                <div className='left'>
                    <p><label>Трапец:</label> {musclesEvaluationSingle('', 'флексия', 60, 70, props.data.values.spine.neck.flexion)}</p>
                    <p><label>Стерноклеидомастоиден мускул:</label> {musclesEvaluationSingle('', 'екстензия', 40, 50, props.data.values.spine.neck.extension)}</p>
                    <p><label>Splenius Capitis:</label> {musclesEvaluationLatRot('', 'ротация', 70, 90, props.data.values.spine.neck.rotation.left, props.data.values.spine.neck.rotation.right)}</p>
                    <p><label>Splenius Cervicis:</label> {musclesEvaluationLatRot('', 'ротация', 70, 90, props.data.values.spine.neck.rotation.left, props.data.values.spine.neck.rotation.right)}</p>
                    <p><label>Levatur Scapulae:</label> {musclesEvaluationLatRot('', 'латерални наклони в шиен дял', 20, 45, props.data.values.spine.neck.lateral.left, props.data.values.spine.neck.lateral.right)}</p>
                    <p><label>Platysma:</label> {musclesEvaluationSingle('', 'екстензия', 40, 50, props.data.values.spine.neck.extension)}</p>
                    <p><label>Гръбни мускули:</label> {props.data.values.spine.curvature.length === 0 ? '' : (props.data.values.spine.curvature.includes('1') ? <span>в норма</span> : <span>мускулен дисбаланс</span>)}</p>
                    <p><label>Quadratus Lumborum:</label> {musclesEvaluationLatRot('', 'латерални наклони в гръбначен стълб', 0, 30, props.data.values.spine.lateral.left, props.data.values.spine.lateral.right)}</p>
                    <p><label>Предно рамо:</label> {musclesEvaluation('', 'екстензия', 0, 60, props.data.values.goniometry.shoulder.left.extension, props.data.values.goniometry.shoulder.right.extension)}</p>
                    <p><label>Задно рамо:</label> {musclesEvaluation('', 'флексия', 0, 180, props.data.values.goniometry.shoulder.left.flexion, props.data.values.goniometry.shoulder.right.flexion)}</p>
                    <p><label>Latisimus Dorsi:</label> {musclesEvaluation('', 'флексия', 0, 180, props.data.values.goniometry.shoulder.left.flexion, props.data.values.goniometry.shoulder.right.flexion)}</p>
                    <p><label>Гръдни мускули:</label> {musclesEvaluation('', 'абдукция', 0, 180, props.data.values.goniometry.shoulder.left.abduction, props.data.values.goniometry.shoulder.right.abduction)}</p>
                    <p><label>Коремни мускули:</label> {ElasticityModel.getElasticityString(props.data.values.elasticity.abdomen)}</p>
                    <p><label>Ахилесово сухожилие:</label> {musclesEvaluation('', 'флексия', 0, 26, props.data.values.goniometry.ankle.left.flexion, props.data.values.goniometry.ankle.right.flexion)}</p>
                    <p><label>Прасец:</label> {musclesEvaluation('', 'флексия', 0, 26, props.data.values.goniometry.ankle.left.flexion, props.data.values.goniometry.ankle.right.flexion)}</p>
                    <p><label>Тибиалис:</label> {musclesEvaluation('', 'екстензия', 0, 48, props.data.values.goniometry.ankle.left.extension, props.data.values.goniometry.ankle.right.extension)}</p>
                    <p><label>Extensor Digitorium Longus:</label> {musclesEvaluation('', 'екстензия', 0, 48, props.data.values.goniometry.ankle.left.extension, props.data.values.goniometry.ankle.right.extension)}</p>
                    <p><label>Peroneus Longus:</label> {musclesEvaluation('', 'екстензия', 0, 48, props.data.values.goniometry.ankle.left.extension, props.data.values.goniometry.ankle.right.extension)}</p>
                    <p><label>Абдуктори долни крайници:</label> {musclesEvaluation('', 'аддукция', 0, 30, props.data.values.goniometry.hip.left.adduction, props.data.values.goniometry.hip.right.adduction)}</p>
                    <p><label>Аддуктори долни крайници:</label> {musclesEvaluation('', 'абдукция', 0, 45, props.data.values.goniometry.hip.left.abduction, props.data.values.goniometry.hip.right.abduction)}</p>
                    <p><label>Четириглав бедрен мускул:</label> {musclesEvaluation('', 'екстензия', 0, 15, props.data.values.goniometry.hip.left.extension, props.data.values.goniometry.hip.right.extension)}</p>
                    <p><label>Сухожилия и мускули на задно бедро:</label> {musclesEvaluation('', 'флексия', 0, 90, props.data.values.goniometry.hip.left.flexionStrKnee, props.data.values.goniometry.hip.right.flexionStrKnee)}</p>
                    <p><label>Глутеус:</label> {musclesEvaluation('', 'флексия', 0, 90, props.data.values.goniometry.hip.left.flexionStrKnee, props.data.values.goniometry.hip.right.flexionStrKnee)}</p>
                    <p><label>Бицепс:</label> {musclesEvaluation('', 'екстензия', 0, 0, props.data.values.goniometry.elbow.left.extension, props.data.values.goniometry.elbow.right.extension)}</p>
                    <p><label>Трицепс:</label> {musclesEvaluation('', 'флексия', 0, 145, props.data.values.goniometry.elbow.left.flexion, props.data.values.goniometry.elbow.right.flexion)}</p>
                    <p><label>Флексори на предмишни мускули:</label> {musclesEvaluation('', 'екстензия', 0, 70, props.data.values.goniometry.wrist.left.extension, props.data.values.goniometry.wrist.right.extension)}</p>
                    <p><label>Екстензори на предмишни мускули:</label> {musclesEvaluation('', 'флексия', 0, 90, props.data.values.goniometry.wrist.left.flexion, props.data.values.goniometry.wrist.right.flexion)}</p>
                </div>
                <div>
                    <div><img src={musclesFront} className="muscles-img view" /></div>
                    <div><img src={musclesBack} className="muscles-img view" /></div>
                </div>
            </div>
            <h2>Сантиметрия</h2>
            <div className='control multi-inp cols wider'>
                <div className='card'>
                    <img src={hip} className="control-img" />
                    <div className='card-mini'>
                        <h3>Бедро:</h3>
                        <label>Ляво:</label> {props.data.values.centimetrics.hip.left && <>{props.data.values.centimetrics.hip.left} см</>}
                        <label>Дясно:</label> {props.data.values.centimetrics.hip.right && <>{props.data.values.centimetrics.hip.right} см</>}
                    </div>
                </div>
                <div className='card'>
                    <img src={shank} className="control-img" />
                    <div className='card-mini'>
                        <h3>Подбедрици:</h3>
                        <label>Лява:</label> {props.data.values.centimetrics.shank.left && <>{props.data.values.centimetrics.shank.left} см</>}
                        <label>Дясна:</label> {props.data.values.centimetrics.shank.right && <>{props.data.values.centimetrics.shank.right} см</>}
                    </div>
                </div>
                <div className='card'>
                    <img src={shoulders} className="control-img" />
                    <div className='card-mini'>
                        <h3>Раменен пояс:</h3>
                        {props.data.values.centimetrics.shoulders && <>{props.data.values.centimetrics.shoulders} см</>}
                    </div>
                </div>
                <div className='card'>
                    <img src={neck_measurement} className="control-img" />
                    <div className='card-mini'>
                        <h3>Врат:</h3>
                        {props.data.values.centimetrics.neck && <>{props.data.values.centimetrics.neck} см</>}
                    </div>
                </div>
                <div className='card'>
                    <img src={abdomen} className="control-img" />
                    <div className='card-mini'>
                        <h3>Корем:</h3>
                        {props.data.values.centimetrics.abdomen && <>{props.data.values.centimetrics.abdomen} см</>}
                    </div>
                </div>
                <div className='card'>
                    <img src={chest} className="control-img" />
                    <div className='card-mini'>
                        <h3>Гърди:</h3>
                        {props.data.values.centimetrics.chest && <>{props.data.values.centimetrics.chest} см</>}
                    </div>
                </div>
                <div className='card'>
                    <img src={biceps} className="control-img" />
                    <div className='card-mini'>
                        <h3>Бицепс:</h3>
                        <label>Ляв:</label> {props.data.values.centimetrics.biceps.left && <>{props.data.values.centimetrics.biceps.left} см</>}
                        <label>Десен:</label> {props.data.values.centimetrics.biceps.right && <>{props.data.values.centimetrics.biceps.right} см</>}
                    </div>
                </div>
                <div className='card'>
                    <img src={forearm} className="control-img" />
                    <div className='card-mini'>
                        <h3>Предмишница:</h3>
                        <label>Лява:</label> {props.data.values.centimetrics.forearm.left && <>{props.data.values.centimetrics.forearm.left} см</>}
                        <label>Дясна:</label> {props.data.values.centimetrics.forearm.right && <>{props.data.values.centimetrics.forearm.right} см</>}
                    </div>
                </div>
                <div className='card'></div>
            </div>
            <div className="important-info-wrapper">Внимание: Сантиметрията може да бъде показател за състоянието на периферната нервна система по крайниците, така също за спортисти и за хора, спазващи режим на хранене.</div>
            <h2>Измервания на дължината на костите на крайниците</h2>
            <div className='multi-inp wider top'>
                <div style={{padding:'1em'}}>
                    <h3>Бедрена кост (Femur):</h3>
                    <div className='control multi-inp'>
                        <p className='control'><label>Ляв крак:</label>{props.data.values.boneLength.femur.left && <>{props.data.values.boneLength.femur.left} см</>}</p>
                        <div><img src={femur} className="control-img" /></div>
                        <p className='control'><label>Десен крак:</label>{props.data.values.boneLength.femur.right && <>{props.data.values.boneLength.femur.right} см</>}</p>
                    </div>
                    <h3>Голям и малък пищял (Tibia):</h3>
                    <div className='control multi-inp'>
                        <p className='control'><label>Ляв крак:</label>{props.data.values.boneLength.tibia.left && <>{props.data.values.boneLength.tibia.left} см</>}</p>
                        <div><img src={tibia} className="control-img" /></div>
                        <p className='control'><label>Десен крак:</label>{props.data.values.boneLength.tibia.right && <>{props.data.values.boneLength.tibia.right} см</>}</p>
                    </div>
                </div>
                <div style={{padding:'1em'}}>
                    <h3>Раменна кост (Humerus):</h3>
                    <div className='control multi-inp'>
                        <p className='control'><label>Ляв крак:</label>{props.data.values.boneLength.humerus.left && <>{props.data.values.boneLength.humerus.left} см</>}</p>
                        <div><img src={humerus} className="control-img" /></div>
                        <p className='control'><label>Десен крак:</label>{props.data.values.boneLength.humerus.right && <>{props.data.values.boneLength.humerus.right} см</>}</p>
                    </div>
                    <h3>Лакътна и лъчева кост (Radius):</h3>
                    <div className='control multi-inp'>
                        <p className='control'><label>Ляв крак:</label>{props.data.values.boneLength.radius.left && <>{props.data.values.boneLength.radius.left} см</>}</p>
                        <div><img src={radius} className="control-img" /></div>
                        <p className='control'><label>Десен крак:</label>{props.data.values.boneLength.radius.right && <>{props.data.values.boneLength.radius.right} см</>}</p>
                    </div>
                </div>
            </div>
            <div className="important-info-wrapper">Диагностиката не се използва като конкретна диагноза, а служи за следене общото състояние на опорно-двигателния апарат във времето, и за взимането на правилни действия с цел балансиране на костна и мускулна система.</div>
        </section>
    );
  });
  
  export default DiagnosticsSheetData;