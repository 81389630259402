import React, { useContext } from 'react';
import { useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { Api } from '../../utils/api';
import ElasticitySelect from './DiagnosticsElasticitySelect';
import Feet from '../../models/Feet';
import Spine from '../../models/Spine';
import CurvModel from '../../models/Curv';
import CervicalModel from '../../models/Cervical';
import LumbarModel from '../../models/Lumbar';
import 'rc-slider/assets/index.css';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import selectCustomStyles from '../../components/UI/SelectCustomStyles';
import DiagnosticsSheetData from './DiagnosticsSheetData';
import DatePicker from 'react-date-picker';
import { convertBase64, musclesEvaluation, musclesEvaluationLatRot, musclesEvaluationSingle } from '../../utils/utils';
import AuthContext from '../../store/auth-context';

import neck from './Images/neck.jpg';
import femur from './Images/femur.jpg';
import tibia from './Images/tibia.jpg';
import humerus from './Images/humerus.jpg';
import radius from './Images/radius.jpg';
import hip from './Images/hip.jpg';
import shank from './Images/shank.jpg';
import chest from './Images/chest.jpg';
import abdomen from './Images/abdomen.jpg';
import shoulders from './Images/shoulders.jpg';
import biceps from './Images/biceps.jpg';
import forearm from './Images/forearm.jpg';
import neck_measurement from './Images/neck_measurement.jpg';
import musclesFront from './Images/muscles_front.jpg';
import musclesBack from './Images/muscles_back.jpg';
import varusNormal from './Images/varus_normal.jpg';
import varusBow from './Images/varus_bow.jpg';
import varusKnock from './Images/varus_knock.jpg';
import supProNeutral from './Images/neutral.jpg';
import supProSupination from './Images/supination.jpg';
import supProOverSupination from './Images/oversupination.jpg';
import supProPronation from './Images/pronation.jpg';
import supProOverPronation from './Images/overpronation.jpg';
import spineHLLKLC from './Images/spine_hyper_lord_lumb_kyph_lord_cerv.jpg';
import spineHLLK from './Images/spine_hyper_lord_lumb_kyph.jpg';
import spineHLL from './Images/spine_hyper_lord_lumb.jpg';
import spineK from './Images/spine_kyph.jpg';
import spineLCFLL from './Images/spine_lord_cerv_flatback_lord_lumb.jpg';
import spineLCHLL from './Images/spine_lord_cerv_hyper_lord_lumb.jpg';
import spineLCK from './Images/spine_lord_cerv_kyph.jpg';
import spineLCLL from './Images/spine_lord_cerv_lord_lumb.jpg';
import spineLC from './Images/spine_lord_cerv.jpg';
import spineLLKLC from './Images/spine_lord_lumb_kyph_lord_cerv.jpg';
import spineLLK from './Images/spine_lord_lumb_kyph.jpg';
import spineLL from './Images/spine_lord_lumb.jpg';
import spineN from './Images/spine_norm.jpg';
import spineSLLTL from './Images/spine_scol_lumb_left_thor_left.jpg';
import spineSLLTR from './Images/spine_scol_lumb_left_thor_right.jpg';
import spineSLL from './Images/spine_scol_lumb_left.jpg';
import spineSLRTL from './Images/spine_scol_lumb_right_thor_left.jpg';
import spineSLRTR from './Images/spine_scol_lumb_right_thor_right.jpg';
import spineSLR from './Images/spine_scol_lumb_right.jpg';
import spineSTL from './Images/spine_scol_thor_left.jpg';
import spineSTR from './Images/spine_scol_thor_right.jpg';
import jointsAnkle from './Images/joints_ankle.jpg';
import jointsElbow from './Images/joints_elbow.jpg';
import jointsElbow1 from './Images/joints_elbow1.jpg';
import jointsHip from './Images/joints_hip.jpg';
import jointsHip1 from './Images/joints_hip1.jpg';
import jointsHip2 from './Images/joints_hip2.jpg';
import jointsKnee from './Images/joints_knee.jpg';
import jointsShoulder from './Images/joints_shoulder.jpg';
import jointsShoulder1 from './Images/joints_shoulder1.jpg';
import jointsShoulder2 from './Images/joints_shoulder2.jpg';
import jointsWrist from './Images/joints_wrist.jpg';
import jointsWrist1 from './Images/joints_wrist1.jpg';

var flatten = require('flat');
var unflatten = require('flat').unflatten;

const DiagnosticsForm = (props) => {
    const authCtx = useContext(AuthContext);
    const data = useLoaderData();
    const meta = data.meta;
    const diagnostics = data.diagnostic;

    const navigate = useNavigate();

    const defaultFormValues = flatten(diagnostics, {delimiter: '_', safe: true});
    const { register, watch, trigger, formState: { errors, isDirty }, handleSubmit, clearErrors, getValues, setValue, control} = useForm({
        defaultValues: defaultFormValues
      });
    
    const spineLumbarDirection = watch("spine_lumbar_direction");
    const [isScolLumbChecked, setIsScolLumbChecked] = useState(getValues('spine_lumbar_curv').includes('1'));
    const [isScolThorChecked, setIsScolThorChecked] = useState(getValues('spine_thoracic_curv').includes('1'));

    const [formStep, setFormStep] = useState(1);
    const [uploadedFile, setUploadedFile] = useState(getValues('feet_image'));

    const handleFileRead = async (event) => {
        const file = event.target.files[0];
        const base64 = await convertBase64(file);
        setUploadedFile(base64);
        setValue('feet_image', base64);
    }

    const handleCheckboxChange = (e, str) => {
        const { value, checked } = e.target;
        const currentValues = getValues(str) || [];
        const newValues = checked
            ? [...currentValues, value]
            : currentValues.filter((v) => v !== value);
        setValue(str, newValues);
    };

    const setTabStep = (step) => {
        trigger(["vitals_medHistory", "patient_id", "dateAdded"]).then((isValid) => {
        if (isValid) {
            clearErrors(["vitals_medHistory", "patient_id", "dateAdded"]);
            setFormStep(step);
       }
        });
    }

    const onNextStep = () => {
        setFormStep((currentStep) => currentStep + 1);
    }

    const onPrevStep = () => {
        setFormStep((currentStep) => currentStep - 1);
    }

    const patientOptions = (inputValue) =>
        new Promise(async (resolve) => {
            const result = await Api.getPatients({search: inputValue});

            const options = [];
            result.patients.forEach((item) => {
                options.push({ value: item.id, label: [item.name, ' ', item.family, ' ', item.age, ' г.'], extra: [item.sex, item.jobTitle, item.dateAdded, item.lastModified] });
            });

            resolve(options);
        }
    );

    const spineCervicalOptions = [
        { value: '1', label: CervicalModel.getCervicalString('1') },
        { value: '2', label: CervicalModel.getCervicalString('2') },
        { value: '3', label: CervicalModel.getCervicalString('3') },
    ];

    const spineLumbarOptions = [
        { value: '1', label: LumbarModel.getLumbarString('1') },
        { value: '2', label: LumbarModel.getLumbarString('2') },
        { value: '3', label: LumbarModel.getLumbarString('3') },
    ];

    const { fields, append, remove } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "blood", // unique name for your Field Array
      });

    const cvtStringToDate = (dateString) => {
        const [d, m, yyyy] = dateString.split('.');
        return new Date(yyyy, m-1, d);
    }

    const cvtDateToString = (date) => {
        if (typeof date === "string") {
            return date;
        } else {
            return `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}.${date.getFullYear()}`;
        }
    }
    
    if (isDirty) {
        setValue('dateModified', new Date());
    }

    const formValues = unflatten(getValues(), {delimiter: '_', safe: true});

    const onFinish = (diagnosticData) => {
        diagnosticData.feet_image = uploadedFile;
        const unflattened = unflatten(diagnosticData, {delimiter: '_', safe: true});
        unflattened.dateAdded = cvtDateToString(unflattened.dateAdded);
        unflattened.dateModified = cvtDateToString(unflattened.dateModified);
        Api.setDiagnostic(unflattened);
        navigate('/diagnostics/' + unflattened.id);
    }

        return (
        <>
            <h1>{meta.title}</h1>
            <form className='form-diagnostics'>
            <section className="lists">
                {formStep != 11 && <>
                <p className='control'>
                    <label>Дата:</label>
                </p>
                <div style={{marginBottom:'0.5em'}}>
                <Controller
                    control={control}
                    render={({ field }) => (
                        <DatePicker
                            locale="bg-BG"
                            format="dd.MM.y"
                            value={typeof field.value === "string" ? cvtStringToDate(field.value) : field.value}
                            onChange={(date) => {
                                clearErrors("dateAdded");
                                field.onChange(date);                            }}
                        />
                    )}
                    name={'dateAdded'}
                    rules={{ required: true }}
                />
                </div>
                {errors.dateAdded && <p className='error-message'>Моля, изберете дата.</p>}
                <p className='control'>
                    <label>Пациент:</label>
                </p>
                <div className='control'>
                    <Controller
                        control={control}
                        render={({field:{onChange, value, name, ref}}) => (
                            <AsyncSelect
                                inputRef={ref}
                                //name={name}
                                cacheOptions
                                defaultValue={meta.action != 'new' && [{value: getValues('patient_id'), label: `${getValues('patient_name')} ${getValues('patient_age')} г.`}]}
                                placeholder={meta.action === 'new' && 'Избери пациент...'}
                                loadOptions={patientOptions}
                                onChange={(selectedOption) => {
                                    clearErrors("patient_id");
                                    onChange(selectedOption.value);
                                    setValue('patient_name', selectedOption.label[0]);
                                    setValue('patient_age', selectedOption.label[2]);
                                    setValue('patient_sex', selectedOption.extra[0]);
                                    setValue('patient_jobTitle', selectedOption.extra[1]);
                                    setValue('patient_dateAdded', selectedOption.extra[2]);
                                    setValue('patient_lastModified', selectedOption.extra[3]);
                                }}
                                styles={selectCustomStyles}
                            />
                            )}
                        name={'patient_id'}
                        // rules={{ required: true }}
                    />
                </div></>}
                {errors.patient_id && <p className='error-message'>Моля, изберете пациент.</p>}
                <ul className="steps">
                    <li className={(formStep === 1) ? 'active' : ''} onClick={() => setTabStep(1)}>Витални</li>
                    <li className={(formStep === 2) ? 'active' : ''} onClick={() => setTabStep(2)}>Кръвни</li>
                    <li className={(formStep === 3) ? 'active' : ''} onClick={() => setTabStep(3)}>Калипер</li>
                    <li className={(formStep === 4) ? 'active' : ''} onClick={() => setTabStep(4)}>Метаболизъм</li>
                    <li className={(formStep === 5) ? 'active' : ''} onClick={() => setTabStep(5)}>Ходила</li>
                    <li className={(formStep === 6) ? 'active' : ''} onClick={() => setTabStep(6)}>Гръбнак</li>
                    <li className={(formStep === 7) ? 'active' : ''} onClick={() => setTabStep(7)}>Подвижност</li>
                    <li className={(formStep === 8) ? 'active' : ''} onClick={() => setTabStep(8)}>Еластичност</li>
                    <li className={(formStep === 9) ? 'active' : ''} onClick={() => setTabStep(9)}>Сантиметрия</li>
                    <li className={(formStep === 10) ? 'active' : ''} onClick={() => setTabStep(10)}>Кости</li>
                    <li className={(formStep === 11) ? 'active' : ''} onClick={() => setTabStep(11)}>Резултат</li>
                </ul>

                    {formStep === 1 && 
                        <>
                    <h2>Витални показатели</h2>
                    <p className='control'>
                        <label>Височина (см):</label>
                        <input
                            {...register("vitals_height")}
                            name="vitals_height"
                            type="number"
                        />
                    </p>
                    <p className='control'>
                        <label>Тегло (кг):</label>
                        <input
                            {...register("vitals_weight")}
                            name="vitals_weight"
                            type="number"
                        />
                    </p>
                    <p className='control'>
                        <label>Кръвно налягане:</label>
                        <input
                            {...register("vitals_bloodPressure")}
                            name="vitals_bloodPressure"
                            type="text"
                        />
                    </p>
                    <p className='control'>
                        <label>Пулс:</label>
                        <input
                            {...register("vitals_pulse")}
                            name="vitals_pulse"
                            type="number"
                        />
                    </p>
                    <p className='control'>
                        <label>Сатурация (%):</label>
                        <input
                            {...register("vitals_saturation")}
                            name="vitals_saturation"
                            type="number"
                        />
                    </p>
                    <p className='control'>
                        <label>Анамнеза:</label>
                        <input
                            {...register("vitals_medHistory", { required: true })}
                            className={errors.vitals_medHistory ? "error-input" : ""}
                            name="vitals_medHistory"
                            type="text"
                            onChange={(e) => {
                                clearErrors("vitals_medHistory");
                              }}
                        />
                    </p>
                    {errors.vitals_medHistory && <p className='error-message'>Моля, въведете анамнеза.</p>}
                    <div className='action'>
                        <button className="prev-next-step" onClick={handleSubmit(onNextStep)}>Кръвни ⇢</button>
                    </div>
                    </>
                    }

                    {formStep === 2 && (
                        <>
                    <h2>Лабораторни кръвни изследвания</h2>
                    {fields.map((field, index) => (
                        <div key={field.id} className='wider multi-inp bottom-aligned'>
                            <div className='card bottom-aligned'>
                            <p className='control short'>
                                <label>Показател:</label>
                                <input
                                    // important to include key with field's id
                                    {...register(`blood.${index}.label`)} 
                                />
                            </p>
                            <p className='control short'>
                            <label>Резултат:</label>
                            <input
                                // important to include key with field's id
                                {...register(`blood.${index}.result`)} 
                            />
                            </p>
                            </div>
                            <div className='card'>
                            <p className='control short'>
                            <label>Ед. и реф. ст-ти:</label>
                            <input
                                // important to include key with field's id
                                {...register(`blood.${index}.units`)} 
                            />
                            </p>
                            <div className='control short'>
                            <label>Заключение:</label>
                            <div style={{padding:'3px'}}>
                            <input
                                // important to include key with field's id
                                {...register(`blood.${index}.conclusion`)}
                                type="radio"
                                value="def"
                            /><span> ⬇️ </span>
                            <input
                                // important to include key with field's id
                                {...register(`blood.${index}.conclusion`)}
                                type="radio"
                                value="close-def"
                            /><span> ↙️ </span>
                            <input
                                // important to include key with field's id
                                {...register(`blood.${index}.conclusion`)}
                                type="radio"
                                value="ok"
                            /><span> 🆗 </span>
                            <input
                                // important to include key with field's id
                                {...register(`blood.${index}.conclusion`)}
                                type="radio"
                                value="ab"
                            /><span> ⬆️ </span>
                            </div>
                            </div>
                            </div>
                            <div className='card'>
                            <button type='button' className='del' onClick={() => remove(index)}></button>
                            </div>
                        </div>
                    ))}
                    <div className='action'>
                        <button type='button' className='' onClick={() => append({
                            label: '',
                            result: null
                        })}>Добави</button>     
                    </div>
                    <div className='action'>
                        <button className='cancel' onClick={handleSubmit(onPrevStep)}>⇠ Витални</button>
                        <button className="prev-next-step" onClick={handleSubmit(onNextStep)}>Калипер ⇢</button>
                    </div>
                    </>)}

                    {formStep === 3 && (
                        <>
                    <h2>Измерване с калипер</h2>
                    <p className='control'>
                        <label>Подскапула (мм):</label>
                        <input
                            {...register("caliper_subscapula")}
                            name="caliper_subscapula"
                            type="number"
                        />
                    </p>
                    <p className='control'>
                        <label>Бицепс (мм):</label>
                        <input
                            {...register("caliper_biceps")}
                            name="caliper_biceps"
                            type="number"
                        />
                    </p>
                    <p className='control'>
                        <label>Трицепс (мм):</label>
                        <input
                            {...register("caliper_triceps")}
                            name="caliper_triceps"
                            type="number"
                        />
                    </p>
                    <p className='control'>
                        <label>Надиалична (мм):</label>
                        <input
                            {...register("caliper_nadialic")}
                            name="caliper_nadialic"
                            type="number"
                        />
                    </p>
                    <div className='action'>
                        <button className='cancel' onClick={handleSubmit(onPrevStep)}>⇠ Кръвни</button>
                        <button className="prev-next-step" onClick={handleSubmit(onNextStep)}>Метаболизъм ⇢</button>
                    </div>
                    </>)}

                    {formStep === 4 && (
                        <>
                    <h2>Метаболизъм</h2>
                    <p className='control'>
                        <label>Мазнини (%):</label>
                        <input
                            {...register("bodyScales_fat")}
                            name="bodyScales_fat"
                            type="number"
                        />
                    </p>
                    {/* <SliderInput /> */}
                    {/* <div className='control'>
                        <Slider min={2} max={55} onChange={value => handleFatSlider(value)} defaultValue={getValues('bodyScales_fat')}
          handleRender={renderProps => {
            return (
                <div {...renderProps.props}>
                    <div style={{width: '30px', position: 'relative', top: '-15px'}}>{fatSlider} %</div>
                </div>
            );
        }} />
                    </div> */}
                    <p className='control'>
                        <label>Хидратация (%):</label>
                        <input
                            {...register("bodyScales_hydration")}
                            name="bodyScales_hydration"
                            type="number"
                        />
                    </p>
                    <p className='control'>
                        <label>Мускули (%):</label>
                        <input
                            {...register("bodyScales_muscles")}
                            name="bodyScales_muscles"
                            type="number"
                        />
                    </p>
                    <p className='control'>
                        <label>Костна маса (кг):</label>
                        <input
                            {...register("bodyScales_bones")}
                            name="bodyScales_bones"
                            type="number"
                        />
                    </p>
                    <p className='control'>
                        <label>Базален метаболизъм (Kcal):</label>
                        <input
                            {...register("bodyScales_basalMetabolism")}
                            name="bodyScales_basalMetabolism"
                            type="number"
                        />
                    </p>
                    <p className='control'>
                        <label>Активен метаболизъм (Kcal):</label>
                        <input
                            {...register("bodyScales_activeMetabolism")}
                            name="bodyScales_activeMetabolism"
                            type="number"
                        />
                    </p>
                    <div className='action'>
                        <button className='cancel' onClick={handleSubmit(onPrevStep)}>⇠ Калипер</button>
                        <button className="prev-next-step" onClick={handleSubmit(onNextStep)}>Ходила ⇢</button>
                    </div>
                    </>)}

                    {formStep === 5 && (
                        <>
                    <h2>Диагностика на ходилата</h2>
                    <p className='control' style={{marginBottom:'1em'}}>
                        <label>Снимка:</label>
                        <input
                            {...register("feet_image")}
                            name="feet_image"
                            type="file"
                            accept=".jpg,.jpeg,.png"
                            onChange={e => handleFileRead(e)}
                        />
                        <img src={uploadedFile} style={{maxWidth: '300px', maxHeight: '300px', margin: '1em 0'}}/>
                    </p>
                    <p className='control'>
                        <label>Ляво ходило:</label>
                    </p>
                    <div className="radio-img-wrapper sup-pro">
                        <div className="container">
                            <input {...register("feet_supPro_left")} type="radio" id="lneutral" value="neutral" />
                            <label htmlFor="lneutral">
                            <img src={supProNeutral} />
                            </label>
                        </div>
                        <div className="container">
                            <input {...register("feet_supPro_left")} type="radio" id="lsupination" value="supination" />
                            <label htmlFor="lsupination">
                            <img src={supProSupination} />
                            </label>
                        </div>
                        <div className="container">
                            <input {...register("feet_supPro_left")} type="radio" id="loversupination" value="oversupination" />
                            <label htmlFor="loversupination">
                            <img src={supProOverSupination} />
                            </label>
                        </div>
                        <div className="container">
                            <input {...register("feet_supPro_left")} type="radio" id="loverpronation" value="overpronation" />
                            <label htmlFor="loverpronation">
                            <img src={supProOverPronation} />
                            </label>
                        </div>
                        <div className="container">
                            <input {...register("feet_supPro_left")} type="radio" id="lpronation" value="pronation" />
                            <label htmlFor="lpronation">
                            <img src={supProPronation} />
                            </label>
                        </div>
                    </div>
                    <p className='left-short'>
                        <input 
                            {...register("feet_left")} 
                            type="checkbox" 
                            value={Feet.feet_norm} 
                            onChange={e => handleCheckboxChange(e, 'feet_left')} 
                            defaultChecked={getValues('feet_left').includes("1")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_norm)}</label><br />
                        <input 
                            {...register("feet_left")} 
                            type="checkbox" 
                            value={Feet.feet_sup} 
                            onChange={e => handleCheckboxChange(e, 'feet_left')} 
                            defaultChecked={getValues('feet_left').includes("2")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_sup)}</label><br />
                        <input 
                            {...register("feet_left")} 
                            type="checkbox" 
                            value={Feet.feet_pron} 
                            onChange={e => handleCheckboxChange(e, 'feet_left')} 
                            defaultChecked={getValues('feet_left').includes("3")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_pron)}</label><br />
                        <input {...register("feet_left")} 
                            type="checkbox" 
                            value={Feet.feet_nlArches} 
                            onChange={e => handleCheckboxChange(e, 'feet_left')} 
                            defaultChecked={getValues('feet_left').includes("4")}/>
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_nlArches)}</label><br />
                        <input 
                            {...register("feet_left")} 
                            type="checkbox" 
                            value={Feet.feet_hlArches} 
                            onChange={e => handleCheckboxChange(e, 'feet_left')} 
                            defaultChecked={getValues('feet_left').includes("5")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_hlArches)}</label><br />
                        <input 
                            {...register("feet_left")} 
                            type="checkbox" 
                            value={Feet.feet_flArches} 
                            onChange={e => handleCheckboxChange(e, 'feet_left')} 
                            defaultChecked={getValues('feet_left').includes("6")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_flArches)}</label><br />
                        <input 
                            {...register("feet_left")} 
                            type="checkbox" 
                            value={Feet.feet_ntArches} 
                            onChange={e => handleCheckboxChange(e, 'feet_left')} 
                            defaultChecked={getValues('feet_left').includes("7")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_ntArches)}</label><br />
                        <input 
                            {...register("feet_left")} 
                            type="checkbox" 
                            value={Feet.feet_ftArches} 
                            onChange={e => handleCheckboxChange(e, 'feet_left')} 
                            defaultChecked={getValues('feet_left').includes("8")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_ftArches)}</label><br />
                        <input 
                            {...register("feet_left")} 
                            type="checkbox" 
                            value={Feet.feet_pressureMoreLeft} 
                            onChange={e => handleCheckboxChange(e, 'feet_left')} 
                            defaultChecked={getValues('feet_left').includes("9")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_pressureMoreLeft)}</label><br />
                        <input 
                            {...register("feet_left")} 
                            type="checkbox" 
                            value={Feet.feet_pressureMoreRight} 
                            onChange={e => handleCheckboxChange(e, 'feet_left')} 
                            defaultChecked={getValues('feet_left').includes("10")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_pressureMoreRight)}</label><br />
                        <input 
                            {...register("feet_left")} 
                            type="checkbox" 
                            value={Feet.feet_flat} 
                            onChange={e => handleCheckboxChange(e, 'feet_left')} 
                            defaultChecked={getValues('feet_left').includes("11")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_flat)}</label>
                    </p>
                    <p className='control'>
                        <label>Дясно ходило:</label>
                    </p>
                    <div className="radio-img-wrapper sup-pro">
                        <div className="container">
                            <input {...register("feet_supPro_right")} type="radio" id="rneutral" value="neutral" />
                            <label htmlFor="rneutral">
                            <img src={supProNeutral} />
                            </label>
                        </div>
                        <div className="container">
                            <input {...register("feet_supPro_right")} type="radio" id="rsupination" value="supination" />
                            <label htmlFor="rsupination">
                            <img src={supProSupination} />
                            </label>
                        </div>
                        <div className="container">
                            <input {...register("feet_supPro_right")} type="radio" id="roversupination" value="oversupination" />
                            <label htmlFor="roversupination">
                            <img src={supProOverSupination} />
                            </label>
                        </div>
                        <div className="container">
                            <input {...register("feet_supPro_right")} type="radio" id="roverpronation" value="overpronation" />
                            <label htmlFor="roverpronation">
                            <img src={supProOverPronation} />
                            </label>
                        </div>
                        <div className="container">
                            <input {...register("feet_supPro_right")} type="radio" id="rpronation" value="pronation" />
                            <label htmlFor="rpronation">
                            <img src={supProPronation} />
                            </label>
                        </div>
                    </div>
                    <p className='left-short'>
                        <input 
                            {...register("feet_right")} 
                            type="checkbox" 
                            value={Feet.feet_norm} 
                            onChange={e => handleCheckboxChange(e, 'feet_right')} 
                            defaultChecked={getValues('feet_right').includes("1")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_norm)}</label><br />
                        <input 
                            {...register("feet_right")} 
                            type="checkbox" 
                            value={Feet.feet_sup} 
                            onChange={e => handleCheckboxChange(e, 'feet_right')} 
                            defaultChecked={getValues('feet_right').includes("2")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_sup)}</label><br />
                        <input 
                            {...register("feet_right")} 
                            type="checkbox" 
                            value={Feet.feet_pron} 
                            onChange={e => handleCheckboxChange(e, 'feet_right')} 
                            defaultChecked={getValues('feet_right').includes("3")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_pron)}</label><br />
                        <input {...register("feet_right")} 
                            type="checkbox" 
                            value={Feet.feet_nlArches} 
                            onChange={e => handleCheckboxChange(e, 'feet_right')} 
                            defaultChecked={getValues('feet_right').includes("4")}/>
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_nlArches)}</label><br />
                        <input 
                            {...register("feet_right")} 
                            type="checkbox" 
                            value={Feet.feet_hlArches} 
                            onChange={e => handleCheckboxChange(e, 'feet_right')} 
                            defaultChecked={getValues('feet_right').includes("5")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_hlArches)}</label><br />
                        <input 
                            {...register("feet_right")} 
                            type="checkbox" 
                            value={Feet.feet_flArches} 
                            onChange={e => handleCheckboxChange(e, 'feet_right')} 
                            defaultChecked={getValues('feet_right').includes("6")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_flArches)}</label><br />
                        <input 
                            {...register("feet_right")} 
                            type="checkbox" 
                            value={Feet.feet_ntArches} 
                            onChange={e => handleCheckboxChange(e, 'feet_right')} 
                            defaultChecked={getValues('feet_right').includes("7")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_ntArches)}</label><br />
                        <input 
                            {...register("feet_right")} 
                            type="checkbox" 
                            value={Feet.feet_ftArches} 
                            onChange={e => handleCheckboxChange(e, 'feet_right')} 
                            defaultChecked={getValues('feet_right').includes("8")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_ftArches)}</label><br />
                        <input 
                            {...register("feet_right")} 
                            type="checkbox" 
                            value={Feet.feet_pressureMoreLeft} 
                            onChange={e => handleCheckboxChange(e, 'feet_right')} 
                            defaultChecked={getValues('feet_right').includes("9")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_pressureMoreLeft)}</label><br />
                        <input 
                            {...register("feet_right")} 
                            type="checkbox" 
                            value={Feet.feet_pressureMoreRight} 
                            onChange={e => handleCheckboxChange(e, 'feet_right')} 
                            defaultChecked={getValues('feet_right').includes("10")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_pressureMoreRight)}</label><br />
                        <input 
                            {...register("feet_right")} 
                            type="checkbox" 
                            value={Feet.feet_flat} 
                            onChange={e => handleCheckboxChange(e, 'feet_right')} 
                            defaultChecked={getValues('feet_right').includes("11")} />
                            <label className='lbl-checkbox'> {Feet.getFeetString(Feet.feet_flat)}</label>
                    </p>
                    <p className='control'>
                    <label>Варусна деформация:</label>
                    </p>
                    <div className="radio-img-wrapper">
                        <div className="container">
                            <input {...register("feet_varus")} type="radio" id="varus_normal" value="normal" />
                            <label htmlFor="varus_normal">
                            <img src={varusNormal} />
                            </label>
                        </div>
                        <div className="container">
                            <input {...register("feet_varus")} type="radio" id="varus_bow" value="bow" />
                            <label htmlFor="varus_bow">
                            <img src={varusBow} />
                            </label>
                        </div>
                        <div className="container">
                            <input {...register("feet_varus")} type="radio" id="varus_knock" value="knock" />
                            <label htmlFor="varus_knock">
                            <img src={varusKnock} />
                            </label>
                        </div>
                    </div>
                    <p className='control'>
                        <label>Препоръки:</label>
                        <input
                            {...register("feet_recommendations")}
                            name="feet_recommendations"
                        />
                    </p>
                    <div className='action'>
                        <button className='cancel' onClick={handleSubmit(onPrevStep)}>⇠ Метаболизъм</button>
                        <button className="prev-next-step" onClick={handleSubmit(onNextStep)}>Гръбнак ⇢</button>
                    </div>
                    </>)}

                    {formStep === 6 && (
                        <>
                    <h2>Диагностика на гръбначен стълб</h2>
                    <h3>Ъглометрия шиен дял:</h3>
                    <img src={neck} className='form-img' />
                    <p className='control'>
                        <label>Флексия (60°-70°):</label>
                        <input
                            {...register("spine_neck_flexion")}
                            type="number"
                            name="spine_neck_flexion"
                        />
                    </p>
                    <p className='control'>
                        <label>Екстензия (40°-50°):</label>
                        <input
                            {...register("spine_neck_extension")}
                            type="number"
                            name="spine_neck_extension"
                        />
                    </p>
                    <p className='control'>
                        <label>Латерални наклони (20°-45°):</label>
                    </p>
                    <div className='control multi-inp'>
                        <p className='control short'>
                            <label>Ляво:</label>
                            <input
                                {...register("spine_neck_lateral_left")}
                                type="number"
                                name="spine_neck_lateral_left"
                            />
                        </p>
                        <p className='control short'>
                            <label>Дясно:</label>
                            <input
                                {...register("spine_neck_lateral_right")}
                                type="number"
                                name="spine_neck_lateral_right"
                            />
                        </p>
                    </div>
                    <p className='control'>
                        <label>Ротация (70°-90°):</label>
                    </p>
                    <div className='control multi-inp'>
                        <p className='control short'>
                            <label>Ляво:</label>
                            <input
                                {...register("spine_neck_rotation_left")}
                                type="number"
                                name="spine_neck_rotation_left"
                            />
                        </p>
                        <p className='control short'>
                            <label>Дясно:</label>
                            <input
                                {...register("spine_neck_rotation_right")}
                                type="number"
                                name="spine_neck_rotation_right"
                            />
                        </p>
                    </div>
                    <h3>Общ преглед на гръбначния стълб:</h3>
                    <div className="radio-img-wrapper spine-curv">
                        {/* <DiagnosticsSpineCheckbox num='1' curv='SpineN' register={register} handleCheckboxChange={handleCheckboxChange} getValues={getValues} /> */}
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineN" 
                                value={Spine.spineN} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("1")}
                            />
                            <label htmlFor="spineN">
                            <img src={spineN} />
                            </label>
                        </div>
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineSLL" 
                                value={Spine.spineSLL} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("2")}
                            />
                            <label htmlFor="spineSLL">
                            <img src={spineSLL} />
                            </label>
                        </div>
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineSLR" 
                                value={Spine.spineSLR} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("3")}
                            />
                            <label htmlFor="spineSLR">
                            <img src={spineSLR} />
                            </label>
                        </div>
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineSTL" 
                                value={Spine.spineSTL} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("4")}
                            />
                            <label htmlFor="spineSTL">
                            <img src={spineSTL} />
                            </label>
                        </div>
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineSTR" 
                                value={Spine.spineSTR} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("5")}
                            />
                            <label htmlFor="spineSTR">
                            <img src={spineSTR} />
                            </label>
                        </div>
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineSLRTL" 
                                value={Spine.spineSLRTL} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("6")}
                            />
                            <label htmlFor="spineSLRTL">
                            <img src={spineSLRTL} />
                            </label>
                        </div>
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineSLRTR" 
                                value={Spine.spineSLRTR} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("9")}
                            />
                            <label htmlFor="spineSLRTR">
                            <img src={spineSLRTR} />
                            </label>
                        </div>
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineSLLTR" 
                                value={Spine.spineSLLTR} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("7")}
                            />
                            <label htmlFor="spineSLLTR">
                            <img src={spineSLLTR} />
                            </label>
                        </div>
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineSLLTL" 
                                value={Spine.spineSLLTL} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("8")}
                            />
                            <label htmlFor="spineSLLTL">
                            <img src={spineSLLTL} />
                            </label>
                        </div>
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineK" 
                                value={Spine.spineK} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("10")}
                            />
                            <label htmlFor="spineK">
                            <img src={spineK} />
                            </label>
                        </div>
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineLL" 
                                value={Spine.spineLL} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("11")}
                            />
                            <label htmlFor="spineLL">
                            <img src={spineLL} />
                            </label>
                        </div>
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineLLK" 
                                value={Spine.spineLLK} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("12")}
                            />
                            <label htmlFor="spineLLK">
                            <img src={spineLLK} />
                            </label>
                        </div>
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineLLKLC" 
                                value={Spine.spineLLKLC} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("13")}
                            />
                            <label htmlFor="spineLLKLC">
                            <img src={spineLLKLC} />
                            </label>
                        </div>
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineHLL" 
                                value={Spine.spineHLL} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("14")}
                            />
                            <label htmlFor="spineHLL">
                            <img src={spineHLL} />
                            </label>
                        </div>
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineHLLK" 
                                value={Spine.spineHLLK} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("15")}
                            />
                            <label htmlFor="spineHLLK">
                            <img src={spineHLLK} />
                            </label>
                        </div>
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineHLLKLC" 
                                value={Spine.spineHLLKLC} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("16")}
                            />
                            <label htmlFor="spineHLLKLC">
                            <img src={spineHLLKLC} />
                            </label>
                        </div>
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineLC" 
                                value={Spine.spineLC} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("17")}
                            />
                            <label htmlFor="spineLC">
                            <img src={spineLC} />
                            </label>
                        </div>
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineLCK" 
                                value={Spine.spineLCK} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("18")}
                            />
                            <label htmlFor="spineLCK">
                            <img src={spineLCK} />
                            </label>
                        </div>
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineLCHLL" 
                                value={Spine.spineLCHLL} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("19")}
                            />
                            <label htmlFor="spineLCHLL">
                            <img src={spineLCHLL} />
                            </label>
                        </div>
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineLCLL" 
                                value={Spine.spineLCLL} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("20")}
                            />
                            <label htmlFor="spineLCLL">
                            <img src={spineLCLL} />
                            </label>
                        </div>
                        <div className="container">
                            <input 
                                {...register("spine_curvature")} 
                                type="checkbox" 
                                id="spineLCFLL" 
                                value={Spine.spineLCFLL} 
                                onChange={e => handleCheckboxChange(e, 'spine_curvature')} 
                                defaultChecked={getValues('spine_curvature').includes("21")}
                            />
                            <label htmlFor="spineLCFLL">
                            <img src={spineLCFLL} />
                            </label>
                        </div>
                    </div>
                    <p className='control'>
                        <label>Измерване на От(C7-S1) 10см <br />(Измерване подвижността на гръбначния стълб)</label>
                        <input
                            {...register("spine_c7s1")}
                            type="number"
                            name="spine_c7s1"
                        />
                    </p>
                    <p className='control'>
                        <label>Измерване на От(Th12) 7.5см <br />(Измерване подвижността на гръбначния стълб)</label>
                        <input
                            {...register("spine_th12")}
                            type="number"
                            name="spine_th12"
                        />
                    </p>
                    <div className='control'>
                        <label>Шиен дял:</label>
                        <div className='control-select'>
                        <Controller
                            control={control}
                            render={({field:{onChange, value, name, ref}}) => (
                                <Select
                                    inputRef={ref}
                                    name={name}
                                    options={spineCervicalOptions}
                                    value={spineCervicalOptions.find(c => c.value === value)}
                                    onChange={(selectedOption) => {
                                        onChange(selectedOption.value);
                                        }}
                                    styles={selectCustomStyles}
                                />
                                )}
                            name='spine_cervical'
                        />
                        </div>
                    </div>
                    <p className='control'>
                        <label>Сила на захват (кг):</label>
                    </p>
                    <div className='control multi-inp'>
                        <p className='control short'>
                            <label>Ляво:</label>
                            <input
                                {...register("spine_grip_left")}
                                type="number"
                                name="spine_grip_left"
                            />
                        </p>
                        <p className='control short'>
                            <label>Дясно:</label>
                            <input
                                {...register("spine_grip_right")}
                                type="number"
                                name="spine_grip_right"
                            />
                        </p>
                    </div>
                    <div className='control'>
                        <label>Гръден дял:</label>
                            <input {...register("spine_thoracic_curv")} 
                                type="checkbox"
                                value={CurvModel.scoliosis}
                                defaultChecked={getValues('spine_thoracic_curv').includes('1')}
                                onChange={e => {handleCheckboxChange(e, 'spine_thoracic_curv'); setIsScolThorChecked(!isScolThorChecked)}}
                            /> {CurvModel.getCurvString(CurvModel.scoliosis)}
                            {isScolThorChecked && <>
                            <span style={{padding:'0 10px'}}><input
                                {...register("spine_thoracic_degrees")}
                                type="number"
                                name="spine_thoracic_degrees"
                                className='tiny'
                            /> °</span>
                            <input {...register("spine_thoracic_direction")} 
                                type="radio"
                                value="ляво"
                            /><label className='lbl-radio'>ляво</label>
                            <input {...register("spine_thoracic_direction")} 
                                type="radio"
                                value="дясно"
                            /><label className='lbl-radio'>дясно</label>
                        </>}
                        <span style={{marginLeft:'10px'}}><input {...register("spine_thoracic_curv")} 
                                type="checkbox"
                                value={CurvModel.kyphosis}
                                defaultChecked={getValues('spine_thoracic_curv').includes('2')}
                                onChange={e => handleCheckboxChange(e, 'spine_thoracic_curv')}
                            /> {CurvModel.getCurvString(CurvModel.kyphosis)}</span>
                    </div>
                    <div className='control'>
                        <label>Лумбален дял:</label>
                        <div className='control-select'>
                        <Controller
                            control={control}
                            render={({field:{onChange, value, name, ref}}) => (
                                <Select
                                    inputRef={ref}
                                    name={name}
                                    options={spineLumbarOptions}
                                    value={spineLumbarOptions.find(l => l.value === value)}
                                    onChange={(selectedOption) => {
                                        onChange(selectedOption.value);
                                        }}
                                    styles={selectCustomStyles}
                                />
                                )}
                            name='spine_lumbar_lord'
                        />
                        </div>
                        <div style={{marginTop:'0.5em'}}>
                            <input {...register("spine_lumbar_curv")} 
                                type="checkbox"
                                value={CurvModel.scoliosis}
                                defaultChecked={getValues('spine_lumbar_curv').includes('1')}
                                onChange={e => {handleCheckboxChange(e, 'spine_lumbar_curv'); setIsScolLumbChecked(!isScolLumbChecked)}}
                            /> {CurvModel.getCurvString(CurvModel.scoliosis)}
                            {isScolLumbChecked && <>
                            <span style={{padding:'0 10px'}}><input
                                {...register("spine_lumbar_degrees")}
                                type="number"
                                name="spine_lumbar_degrees"
                                className='tiny'
                            /> °</span>
                            <input {...register("spine_lumbar_direction")} 
                                type="radio"
                                id="left"
                                value="ляво"
                            /><label className='lbl-radio' htmlFor='left'>ляво</label>
                            <input {...register("spine_lumbar_direction")} 
                                type="radio"
                                id="right"
                                value="дясно"
                            /><label className='lbl-radio' htmlFor='right'>дясно</label>
                            </>}
                        </div>
                    </div>
                    <p className='control'>
                        <label>Латерални наклони (30°):</label>
                    </p>
                    <div className='control multi-inp'>
                        <p className='control short'>
                            <label>Ляво:</label>
                            <input
                                {...register("spine_lateral_left")}
                                type="number"
                                name="spine_lateral_left"
                            />
                        </p>
                        <p className='control short'>
                            <label>Дясно:</label>
                            <input
                                {...register("spine_lateral_right")}
                                type="number"
                                name="spine_lateral_right"
                            />
                        </p>
                    </div>
                    <div className='control'>
                        <label>Сакрален дял:</label>
                        {/* <input
                            {...register("spine_sacrum")}
                            name="spine_sacrum"
                        /> */}
                        {!isScolLumbChecked ? <p>Няма изменение.</p> : (spineLumbarDirection === 'ляво' ? <p>Тазът се усуква наляво.</p> : <p>Тазът се усуква надясно.</p>)}
                    </div>
                    {/* <p className='control'>
                        <label>Препоръки:</label>
                        <input
                            {...register("spine_recommendations")}
                            name="spine_recommendations"
                        />
                    </p> */}
                    <p className='control'>
                        <label>Бележка (сколиометър):</label>
                        <textarea
                            {...register("spine_scoliometerNote")}
                            name="spine_scoliometerNote"
                            rows='3'
                        />
                    </p>
                    <div className='action'>
                        <button className='cancel' onClick={handleSubmit(onPrevStep)}>⇠ Ходила</button>
                        <button className="prev-next-step" onClick={handleSubmit(onNextStep)}>Подвижност ⇢</button>
                    </div>
                    </>)}

                    {formStep === 7 && 
                        <>
                    <h2>Ъглометрия - подвижност на стави</h2>
                    <h3>Тазобедрена става</h3>
                    <div className='control multi-inp wider top'>
                        <div className='wide-col'>
                            <h4>Екстензия-Флексия (15°-0°-125°):</h4>
                            <div className='control multi-inp'>
                                <p className='control short'>
                                    <label>Лява:</label>
                                    <input
                                        {...register("goniometry_hip_left_extension")}
                                        name="goniometry_hip_left_extension"
                                        type="number"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_hip_left_flexion")}
                                        name="goniometry_hip_left_flexion"
                                        type="number"
                                        className='tiny'
                                    />
                                </p>
                                <p className='control short'>
                                    <label>Дясна:</label>
                                    <input
                                        {...register("goniometry_hip_right_extension")}
                                        name="goniometry_hip_right_extension"
                                        type="number"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_hip_right_flexion")}
                                        name="goniometry_hip_right_flexion"
                                        type="number"
                                        className='tiny'
                                    />
                                </p>
                            </div>
                            <div className='control multi-inp'>
                                <p className='control short'>
                                <label>С изпънато коляно (90°):</label>
                                    <input
                                        {...register("goniometry_hip_left_flexionStrKnee")}
                                        name="goniometry_hip_left_flexionStrKnee"
                                        type="number"
                                        className='tiny'
                                    />
                                </p>
                                <p className='control short'>
                                <label>С изпънато коляно (90°):</label>
                                    <input
                                        {...register("goniometry_hip_right_flexionStrKnee")}
                                        name="goniometry_hip_right_flexionStrKnee"
                                        type="number"
                                        className='tiny'
                                    />
                                </p>
                            </div>
                            <h4>Абдукция-Аддукция (45°-0°-30°):</h4>
                            <div className='control multi-inp'>
                                <p className='control short'>
                                    <label>Лява:</label>
                                    <input
                                        {...register("goniometry_hip_left_abduction")}
                                        name="goniometry_hip_left_abduction"
                                        type="number"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_hip_left_adduction")}
                                        name="goniometry_hip_left_adduction"
                                        type="number"
                                        className='tiny'
                                    />
                                </p>
                                <p className='control short'>
                                    <label>Дясна:</label>
                                    <input
                                        {...register("goniometry_hip_right_abduction")}
                                        name="goniometry_hip_right_abduction"
                                        type="number"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_hip_right_adduction")}
                                        name="goniometry_hip_right_adduction"
                                        type="number"
                                        className='tiny'
                                    />
                                </p>
                            </div>
                            <h4>Вътрешна-Външна Ротация (45°-0°-45°):</h4>
                            <div className='control multi-inp'>
                                <p className='control short'>
                                    <label>Лява:</label>
                                    <input
                                        {...register("goniometry_hip_left_rotationInner")}
                                        name="goniometry_hip_left_rotationInner"
                                        type="number"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_hip_left_rotationOuter")}
                                        name="goniometry_hip_left_rotationOuter"
                                        type="number"
                                        className='tiny'
                                    />
                                </p>
                                <p className='control short'>
                                    <label>Дясна:</label>
                                    <input
                                        {...register("goniometry_hip_right_rotationInner")}
                                        name="goniometry_hip_right_rotationInner"
                                        type="number"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_hip_right_rotationOuter")}
                                        name="goniometry_hip_right_rotationOuter"
                                        type="number"
                                        className='tiny'
                                    />
                                </p>
                            </div>
                        </div>
                        <div className='right-col-imgs'>
                            <div><img src={jointsHip} className="goniometry-img"/></div>
                            <div><img src={jointsHip1} className="goniometry-img" /></div>
                            <div><img src={jointsHip2} className="goniometry-img" /></div>
                        </div>
                    </div>
                    <h3>Раменна става</h3>
                    <div className='control multi-inp wider top'>
                        <div className='wide-col'>
                            <h4>Екстензия-Флексия (60°-0°-180°):</h4>
                            <div className='control multi-inp'>
                                <p className='control short'>
                                    <label>Лява:</label>
                                    <input
                                        {...register("goniometry_shoulder_left_extension")}
                                        name="goniometry_shoulder_left_extension"
                                        type="number"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_shoulder_left_flexion")}
                                        name="goniometry_shoulder_left_flexion"
                                        type="number"
                                        className='tiny'
                                    />
                                </p>
                                <p className='control short'>
                                    <label>Дясна:</label>
                                    <input
                                        {...register("goniometry_shoulder_right_extension")}
                                        name="goniometry_shoulder_right_extension"
                                        type="number"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_shoulder_right_flexion")}
                                        name="goniometry_shoulder_right_flexion"
                                        type="number"
                                        className='tiny'
                                    />
                                </p>
                            </div>
                            <h4>Абдукция-Аддукция (180°-0°-0°):</h4>
                            <div className='control multi-inp'>
                                <p className='control short'>
                                    <label>Лява:</label>
                                    <input
                                        {...register("goniometry_shoulder_left_abduction")}
                                        name="goniometry_shoulder_left_abduction"
                                        type="number"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_shoulder_left_adduction")}
                                        name="goniometry_shoulder_left_adduction"
                                        type="number"
                                        className='tiny'
                                    />
                                </p>
                                <p className='control short'>
                                    <label>Дясна:</label>
                                    <input
                                        {...register("goniometry_shoulder_right_abduction")}
                                        name="goniometry_shoulder_right_abduction"
                                        type="number"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_shoulder_right_adduction")}
                                        name="goniometry_shoulder_right_adduction"
                                        type="number"
                                        className='tiny'
                                    />
                                </p>
                            </div>
                            <h4>Вътрешна-Външна Ротация (90°-0°-90°):</h4>
                            <div className='control multi-inp'>
                                <p className='control short'>
                                    <label>Лява:</label>
                                    <input
                                        {...register("goniometry_shoulder_left_rotationInner")}
                                        name="goniometry_shoulder_left_rotationInner"
                                        type="number"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_shoulder_left_rotationOuter")}
                                        name="goniometry_shoulder_left_rotationOuter"
                                        type="number"
                                        className='tiny'
                                    />
                                </p>
                                <p className='control short'>
                                    <label>Дясна:</label>
                                    <input
                                        {...register("goniometry_shoulder_right_rotationInner")}
                                        name="goniometry_shoulder_right_rotationInner"
                                        type="number"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_shoulder_right_rotationOuter")}
                                        name="goniometry_shoulder_right_rotationOuter"
                                        type="number"
                                        className='tiny'
                                    />
                                </p>
                            </div>
                        </div>
                        <div className='right-col-imgs'>
                            <div><img src={jointsShoulder} className="goniometry-img"/></div>
                            <div><img src={jointsShoulder1} className="goniometry-img" /></div>
                            <div><img src={jointsShoulder2} className="goniometry-img" /></div>
                        </div>
                    </div>
                    <h3>Глезенна става</h3>
                    <div className='control multi-inp wider top'>
                        <div className='wide-col'>
                            <h4>Флексия-Екстензия (26°-0°-48°):</h4>
                            <div className='control multi-inp'>
                                <p className='control short'>
                                    <label>Лява:</label>
                                    <input
                                        {...register("goniometry_ankle_left_flexion")}
                                        name="goniometry_ankle_left_flexion"
                                        type="number"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_ankle_left_extension")}
                                        name="goniometry_ankle_left_extension"
                                        type="number"
                                        className='tiny'
                                    />
                                </p>
                                <p className='control short'>
                                    <label>Дясна:</label>
                                    <input
                                        {...register("goniometry_ankle_right_flexion")}
                                        name="goniometry_ankle_right_flexion"
                                        type="number"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_ankle_right_extension")}
                                        name="goniometry_ankle_right_extension"
                                        type="number"
                                        className='tiny'
                                    />
                                </p>
                            </div>
                        </div>
                        <div className='right-col-imgs'>
                            <div><img src={jointsAnkle} className="goniometry-img"/></div>
                        </div>
                    </div>
                    <h3>Колянна става</h3>
                    <div className='control multi-inp wider top'>
                        <div className='wide-col'>
                            <h4>Екстензия-Флексия (0°-0°-130°):</h4>
                            <div className='control multi-inp'>
                                <p className='control short'>
                                    <label>Лява:</label>
                                    <input
                                        {...register("goniometry_knee_left_extension")}
                                        name="goniometry_knee_left_extension"
                                        type="number"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_knee_left_flexion")}
                                        name="goniometry_knee_left_flexion"
                                        type="number"
                                        className='tiny'
                                    />
                                </p>
                                <p className='control short'>
                                    <label>Дясна:</label>
                                    <input
                                        {...register("goniometry_knee_right_extension")}
                                        name="goniometry_knee_right_extension"
                                        type="number"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_knee_right_flexion")}
                                        name="goniometry_knee_right_flexion"
                                        type="number"
                                        className='tiny'
                                    />
                                </p>
                            </div>
                        </div>
                        <div className='right-col-imgs'>
                            <div><img src={jointsKnee} className="goniometry-img"/></div>
                        </div>
                    </div>
                    <h3>Лакътна става</h3>
                    <div className='control multi-inp wider top'>
                        <div className='wide-col'>
                            <h4>Екстензия-Флексия (0°-0°-145°):</h4>
                            <div className='control multi-inp'>
                                <p className='control short'>
                                    <label>Лява:</label>
                                    <input
                                        {...register("goniometry_knee_left_extension")}
                                        name="goniometry_knee_left_extension"
                                        type="number"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_knee_left_flexion")}
                                        name="goniometry_knee_left_flexion"
                                        type="number"
                                        className='tiny'
                                    />
                                </p>
                                <p className='control short'>
                                    <label>Дясна:</label>
                                    <input
                                        {...register("goniometry_knee_right_extension")}
                                        name="goniometry_knee_right_extension"
                                        type="number"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_knee_right_flexion")}
                                        name="goniometry_knee_right_flexion"
                                        type="number"
                                        className='tiny'
                                    />
                                </p>
                            </div>
                            <h4>Супинация-Пронация (90°-0°-90°):</h4>
                            <div className='control multi-inp'>
                                <p className='control short'>
                                    <label>Лява:</label>
                                    <input
                                        {...register("goniometry_elbow_left_supination")}
                                        name="goniometry_elbow_left_supination"
                                        type="number"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_elbow_left_pronation")}
                                        name="goniometry_elbow_left_pronation"
                                        type="number"
                                        className='tiny'
                                    />
                                </p>
                                <p className='control short'>
                                    <label>Дясна:</label>
                                    <input
                                        {...register("goniometry_elbow_right_supination")}
                                        name="goniometry_elbow_right_supination"
                                        type="number"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_elbow_right_pronation")}
                                        name="goniometry_elbow_right_pronation"
                                        type="number"
                                        className='tiny'
                                    />
                                </p>
                            </div>
                        </div>
                        <div className='right-col-imgs'>
                            <div><img src={jointsElbow} className="goniometry-img"/></div>
                            <div><img src={jointsElbow1} className="goniometry-img"/></div>
                        </div>
                    </div>
                    <h3>Става на китката</h3>
                    <div className='control multi-inp wider top'>
                        <div className='wide-col'>
                            <h4>Екстензия-Флексия (70°-0°-90°):</h4>
                            <div className='control multi-inp'>
                                <p className='control short'>
                                    <label>Лява:</label>
                                    <input
                                        {...register("goniometry_wrist_left_extension")}
                                        name="goniometry_wrist_left_extension"
                                        type="number"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_wrist_left_flexion")}
                                        name="goniometry_wrist_left_flexion"
                                        type="number"
                                        className='tiny'
                                    />
                                </p>
                                <p className='control short'>
                                    <label>Дясна:</label>
                                    <input
                                        {...register("goniometry_wrist_right_extension")}
                                        name="goniometry_wrist_right_extension"
                                        type="number"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_wrist_right_flexion")}
                                        name="goniometry_wrist_right_flexion"
                                        type="number"
                                        className='tiny'
                                    />
                                </p>
                            </div>
                            <h4>Радиално-Улнарно отвеждане (20°-0°-40°):</h4>
                            <div className='control multi-inp'>
                                <p className='control short'>
                                    <label>Лява:</label>
                                    <input
                                        {...register("goniometry_wrist_left_radial")}
                                        name="goniometry_wrist_left_radial"
                                        type="number"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_wrist_left_ulnar")}
                                        name="goniometry_wrist_left_ulnar"
                                        type="number"
                                        className='tiny'
                                    />
                                </p>
                                <p className='control short'>
                                    <label>Дясна:</label>
                                    <input
                                        {...register("goniometry_wrist_right_radial")}
                                        name="goniometry_wrist_right_radial"
                                        type="number"
                                        className='tiny'
                                    />
                                    <span> -0°- </span>
                                    <input
                                        {...register("goniometry_wrist_right_ulnar")}
                                        name="goniometry_wrist_right_ulnar"
                                        type="number"
                                        className='tiny'
                                    />
                                </p>
                            </div>
                        </div>
                        <div className='right-col-imgs'>
                            <div><img src={jointsWrist} className="goniometry-img"/></div>
                            <div><img src={jointsWrist1} className="goniometry-img"/></div>
                        </div>
                    </div>
                    <div className='action'>
                        <button className='cancel' onClick={handleSubmit(onPrevStep)}>⇠ Гръбнак</button>
                        <button className="prev-next-step" onClick={handleSubmit(onNextStep)}>Еластичност ⇢</button>
                    </div>
                    </>
                    }

                    {formStep === 8 && 
                        <>
                    <h2>Оценка за еластичност на основни сухожилия и мускули</h2>
                    <div className='multi-inp wider top'>
                        <div className='left half'>
                            <div className='control-single-line'>
                                <label>Трапец</label>
                                {musclesEvaluationSingle('', 'флексия', 60, 70, getValues('spine_neck_flexion'))}
                            </div>
                            <div className='control-single-line'>
                                <label>Стерноклеидомастоиден мускул</label>
                                {musclesEvaluationSingle('', 'екстензия', 40, 50, getValues('spine_neck_extension'))}
                            </div>
                            <div className='control-single-line'>
                                <label>Splenius Capitis</label>
                                {musclesEvaluationLatRot('', 'ротация', 70, 90, getValues('spine_neck_rotation_left'), getValues('spine_neck_rotation_right'))}
                            </div>
                            <div className='control-single-line'>
                                <label>Splenius Cervicis</label>
                                {musclesEvaluationLatRot('', 'ротация', 70, 90, getValues('spine_neck_rotation_left'), getValues('spine_neck_rotation_right'))}
                            </div>
                            <div className='control-single-line'>
                                <label>Levatur Scapulae</label>
                                {musclesEvaluationLatRot('', 'латерални наклони в шиен дял', 20, 45, getValues('spine_neck_lateral_left'), getValues('spine_neck_lateral_right'))}
                            </div>
                            <div className='control-single-line'>
                                <label>Platysma</label>
                                {musclesEvaluationSingle('', 'екстензия', 40, 50, getValues('spine_neck_extension'))}
                            </div>
                            <div className='control-single-line'>
                                <label>Гръбни мускули</label>
                                {getValues('spine_curvature').length === 0 ? '' : (getValues('spine_curvature').includes('1') ? <span>в норма</span> : <span>мускулен дисбаланс</span>)}
                            </div>
                            <div className='control-single-line'>
                                <label>Quadratus Lumborum</label>
                                {musclesEvaluationLatRot('', 'латерални наклони в гръбначен стълб', 0, 30, getValues('spine_lateral_left'), getValues('spine_lateral_right'))}
                            </div>
                            <div className='control-single-line'>
                                <label>Предно рамо</label>
                                {musclesEvaluation('', 'екстензия', 0, 60, getValues('goniometry_shoulder_left_extension'), getValues('goniometry_shoulder_right_extension'))}
                            </div>
                            <div className='control-single-line'>
                                <label>Задно рамо</label>
                                {musclesEvaluation('', 'флексия', 0, 180, getValues('goniometry_shoulder_left_flexion'), getValues('goniometry_shoulder_right_flexion'))}
                            </div>
                            <div className='control-single-line'>
                                <label>Latisimus Dorsi</label>
                                {musclesEvaluation('', 'флексия', 0, 180, getValues('goniometry_shoulder_left_flexion'), getValues('goniometry_shoulder_right_flexion'))}
                            </div>
                            <div className='control-single-line'>
                                <label>Гръдни мускули</label>
                                {musclesEvaluation('', 'абдукция', 0, 180, getValues('goniometry_shoulder_left_abduction'), getValues('goniometry_shoulder_right_abduction'))}
                            </div>
                            <ElasticitySelect control={control} name={'elasticity_abdomen'} label={'Коремни мускули'} />
                            <div className='control-single-line'>
                                <label>Ахилесово сухожилие</label>
                                {musclesEvaluation('', 'флексия', 0, 26, getValues('goniometry_ankle_left_flexion'), getValues('goniometry_ankle_right_flexion'))}
                            </div>
                            <div className='control-single-line'>
                                <label>Прасец</label>
                                {musclesEvaluation('', 'флексия', 0, 26, getValues('goniometry_ankle_left_flexion'), getValues('goniometry_ankle_right_flexion'))}
                            </div>
                            <div className='control-single-line'>
                                <label>Тибиалис</label>
                                {musclesEvaluation('', 'екстензия', 0, 48, getValues('goniometry_ankle_left_extension'), getValues('goniometry_ankle_right_extension'))}
                            </div>
                            <div className='control-single-line'>
                                <label>Extensor Digitorium Longus</label>
                                {musclesEvaluation('', 'екстензия', 0, 48, getValues('goniometry_ankle_left_extension'), getValues('goniometry_ankle_right_extension'))}
                            </div>
                            <div className='control-single-line'>
                                <label>Peroneus Longus</label>
                                {musclesEvaluation('', 'екстензия', 0, 48, getValues('goniometry_ankle_left_extension'), getValues('goniometry_ankle_right_extension'))}
                            </div>
                            <div className='control-single-line'>
                                <label>Абдуктори долни крайници</label>
                                {musclesEvaluation('', 'аддукция', 0, 30, getValues('goniometry_hip_left_adduction'), getValues('goniometry_hip_right_adduction'))}
                            </div>
                            <div className='control-single-line'>
                                <label>Аддуктори долни крайници</label>
                                {musclesEvaluation('', 'абдукция', 0, 45, getValues('goniometry_hip_left_abduction'), getValues('goniometry_hip_right_abduction'))}
                            </div>
                            <div className='control-single-line'>
                                <label>Четириглав бедрен мускул</label>
                                {musclesEvaluation('', 'екстензия', 0, 15, getValues('goniometry_hip_left_extension'), getValues('goniometry_hip_right_extension'))}
                            </div>
                            <div className='control-single-line'>
                                <label>Сухожилия и мускули на задно бедро</label>
                                {musclesEvaluation('', 'флексия', 0, 90, getValues('goniometry_hip_left_flexionStrKnee'), getValues('goniometry_hip_right_flexionStrKnee'))}
                            </div>
                            <div className='control-single-line'>
                                <label>Глутеус</label>
                                {musclesEvaluation('', 'флексия', 0, 90, getValues('goniometry_hip_left_flexionStrKnee'), getValues('goniometry_hip_right_flexionStrKnee'))}
                            </div>
                            <div className='control-single-line'>
                                <label>Бицепс</label>
                                {musclesEvaluation('', 'екстензия', 0, 0, getValues('goniometry_elbow_left_extension'), getValues('goniometry_elbow_right_extension'))}
                            </div>
                            <div className='control-single-line'>
                                <label>Трицепс</label>
                                {musclesEvaluation('', 'флексия', 0, 145, getValues('goniometry_elbow_left_flexion'), getValues('goniometry_elbow_right_flexion'))}
                            </div>
                            <div className='control-single-line'>
                                <label>Флексори на предмишни мускули</label>
                                {musclesEvaluation('', 'екстензия', 0, 70, getValues('goniometry_wrist_left_extension'), getValues('goniometry_wrist_right_extension'))}
                            </div>
                            <div className='control-single-line'>
                                <label>Екстензори на предмишни мускули</label>
                                {musclesEvaluation('', 'флексия', 0, 90, getValues('goniometry_wrist_left_flexion'), getValues('goniometry_wrist_right_flexion'))}
                            </div>
                            {/* <ElasticitySelect control={control} name={'elasticity_trapezius'} label={'Трапец'} />
                            <ElasticitySelect control={control} name={'elasticity_sternocleidomastoid'} label={'Стерноклеидомастоиден мускул'} />
                            <ElasticitySelect control={control} name={'elasticity_spleniusCapitis'} label={'Splenius Capitis'} />
                            <ElasticitySelect control={control} name={'elasticity_spleniusCervicis'} label={'Splenius Cervicis'} />
                            <ElasticitySelect control={control} name={'elasticity_levaturScapulae'} label={'Levatur Scapulae'} />
                            <ElasticitySelect control={control} name={'elasticity_platysma'} label={'Platysma'} />
                            <ElasticitySelect control={control} name={'elasticity_back'} label={'Гръбни мускули'} />
                            <ElasticitySelect control={control} name={'elasticity_quadratusLumborum'} label={'Quadratus Lumborum'} />
                            <ElasticitySelect control={control} name={'elasticity_frontShoulder'} label={'Предно рамо'} />
                            <ElasticitySelect control={control} name={'elasticity_backShoulder'} label={'Задно рамо'} />
                            <ElasticitySelect control={control} name={'elasticity_latisimusDorsi'} label={'Latisimus Dorsi'} />
                            <ElasticitySelect control={control} name={'elasticity_chest'} label={'Гръдни мускули'} />
                            <ElasticitySelect control={control} name={'elasticity_abdomen'} label={'Коремни мускули'} />
                            <ElasticitySelect control={control} name={'elasticity_achilles'} label={'Ахилесово сухожилие'} />
                            <ElasticitySelect control={control} name={'elasticity_calf'} label={'Прасец'} />
                            <ElasticitySelect control={control} name={'elasticity_tibialis'} label={'Тибиалис'} />
                            <ElasticitySelect control={control} name={'elasticity_extensorDigitoriumLongus'} label={'Extensor Digitorium Longus'} />
                            <ElasticitySelect control={control} name={'elasticity_peroneusLongus'} label={'Peroneus Longus'} />
                            <ElasticitySelect control={control} name={'elasticity_abductors'} label={'Абдуктори долни крайници'} />
                            <ElasticitySelect control={control} name={'elasticity_adductors'} label={'Аддуктори долни крайници'} />
                            <ElasticitySelect control={control} name={'elasticity_quadicepsFemoris'} label={'Четириглав бедрен мускул'} />
                            <ElasticitySelect control={control} name={'elasticity_hamstrings'} label={'Сухожилия и мускули на задно бедро'} />
                            <ElasticitySelect control={control} name={'elasticity_gluteus'} label={'Глутеус'} />
                            <ElasticitySelect control={control} name={'elasticity_biceps'} label={'Бицепс'} />
                            <ElasticitySelect control={control} name={'elasticity_triceps'} label={'Трицепс'} />
                            <ElasticitySelect control={control} name={'elasticity_flexorForearm'} label={'Флексори на предмишни мускули'} />
                            <ElasticitySelect control={control} name={'elasticity_extensorForearm'} label={'Екстензори на предмишни мускули'} /> */}
                        </div>
                        <div>
                            <div><img src={musclesFront} className="muscles-img" /></div>
                            <div><img src={musclesBack} className="muscles-img" /></div>
                        </div>
                    </div>
                    <div className='action'>
                        <button className='cancel' onClick={handleSubmit(onPrevStep)}>⇠ Подвижност</button>
                        <button className="prev-next-step" onClick={handleSubmit(onNextStep)}>Сантиметрия ⇢</button>
                    </div>
                    </>
                    }

                    {formStep === 9 && 
                        <>
                    <h2>Сантиметрия</h2>
                    <div className='control multi-inp wider'>
                        <div className='card'>
                            <img src={hip} className="control-img" />
                            <p className='control short'>
                                <h3>Бедро:</h3>
                                <label>Ляво:</label>
                                <input
                                    {...register("centimetrics_hip_left")}
                                    name="centimetrics_hip_left"
                                    type="number"
                                />
                                <label>Дясно:</label>
                                <input
                                    {...register("centimetrics_hip_right")}
                                    name="centimetrics_hip_right"
                                    type="number"
                                />
                            </p>
                        </div>
                        <div className='card'>
                            <img src={shank} className="control-img" />
                            <p className='control short'>
                                <h3>Подбедрици:</h3>
                                <label>Лява:</label>
                                <input
                                    {...register("centimetrics_shank_left")}
                                    name="centimetrics_shank_left"
                                    type="number"
                                />
                                <label>Дясна:</label>
                                <input
                                    {...register("centimetrics_shank_right")}
                                    name="centimetrics_shank_right"
                                    type="number"
                                />
                            </p>
                        </div>
                        <div className='card'>
                            <img src={shoulders} className="control-img" />
                            <p className='control short'>
                                <h3>Раменен пояс:</h3>
                                <input
                                    {...register("centimetrics_shoulders")}
                                    name="centimetrics_shoulders"
                                    type="number"
                                />
                            </p>
                        </div>
                        <div className='card'>
                            <img src={neck_measurement} className="control-img" />
                            <p className='control short'>
                                <h3>Врат:</h3>
                                <input
                                    {...register("centimetrics_neck")}
                                    name="centimetrics_neck"
                                    type="number"
                                />
                            </p>
                        </div>
                        <div className='card'>
                            <img src={abdomen} className="control-img" />
                            <p className='control short'>
                                <h3>Корем:</h3>
                                <input
                                    {...register("centimetrics_abdomen")}
                                    name="centimetrics_abdomen"
                                    type="number"
                                />
                            </p>
                        </div>
                        <div className='card'>
                            <img src={chest} className="control-img" />
                            <p className='control short'>
                                <h3>Гърди:</h3>
                                <input
                                    {...register("centimetrics_chest")}
                                    name="centimetrics_chest"
                                    type="number"
                                />
                            </p>
                        </div>
                        <div className='card'>
                            <img src={biceps} className="control-img" />
                            <p className='control short'>
                                <h3>Бицепс:</h3>
                                <label>Ляв:</label>
                                <input
                                    {...register("centimetrics_biceps_left")}
                                    name="centimetrics_biceps_left"
                                    type="number"
                                />
                                <label>Десен:</label>
                                <input
                                    {...register("centimetrics_biceps_right")}
                                    name="centimetrics_biceps_right"
                                    type="number"
                                />
                            </p>
                        </div>
                        <div className='card'>
                            <img src={forearm} className="control-img" />
                            <p className='control short'>
                                <h3>Предмишница:</h3>
                                <label>Лява:</label>
                                <input
                                    {...register("centimetrics_forearm_left")}
                                    name="centimetrics_forearm_left"
                                    type="number"
                                />
                                <label>Дясна:</label>
                                <input
                                    {...register("centimetrics_forearm_right")}
                                    name="centimetrics_forearm_right"
                                    type="number"
                                />
                            </p>
                        </div>
                        <div className='card'></div>
                    </div>
                  
                    <div className='action'>
                        <button className='cancel' onClick={handleSubmit(onPrevStep)}>⇠ Еластичност</button>
                        <button className="prev-next-step" onClick={handleSubmit(onNextStep)}>Кости ⇢</button>
                    </div>
                    </>
                    }

                    {formStep === 10 && 
                        <>
                    <h2>Измервания на дължината на костите на крайниците</h2>
                    <p className='control'>
                        <h3>Бедрена кост (Femur):</h3>
                    </p>
                    <div className='control multi-inp'>
                        <p className='control short'>
                            <label>Ляв крак:</label>
                            <input
                                {...register("boneLength_femur_left")}
                                name="boneLength_femur_left"
                                type="number"
                            />
                        </p>
                        <div><img src={femur} className="control-img" /></div>
                        <p className='control short'>
                            <label>Десен крак:</label>
                            <input
                                {...register("boneLength_femur_right")}
                                name="boneLength_femur_right"
                                type="number"
                            />
                        </p>
                    </div>
                    <p className='control'>
                        <h3>Голям и малък пищял (Tibia):</h3>
                    </p>
                    <div className='control multi-inp'>
                        <p className='control short'>
                            <label>Ляв крак:</label>
                            <input
                                {...register("boneLength_tibia_left")}
                                name="boneLength_tibia_left"
                                type="number"
                            />
                        </p>
                        <div><img src={tibia} className="control-img" /></div>
                        <p className='control short'>
                            <label>Десен крак:</label>
                            <input
                                {...register("boneLength_tibia_right")}
                                name="boneLength_tibia_right"
                                type="number"
                            />
                        </p>
                    </div>
                    <p className='control'>
                        <h3>Раменна кост (Humerus):</h3>
                    </p>
                    <div className='control multi-inp'>
                        <p className='control short'>
                            <label>Ляв крак:</label>
                            <input
                                {...register("boneLength_humerus_left")}
                                name="boneLength_humerus_left"
                                type="number"
                            />
                        </p>
                        <div><img src={humerus} className="control-img" /></div>
                        <p className='control short'>
                            <label>Десен крак:</label>
                            <input
                                {...register("boneLength_humerus_right")}
                                name="boneLength_humerus_right"
                                type="number"
                            />
                        </p>
                    </div>
                    <p className='control'>
                        <h3>Лакътна и лъчева кост (Radius):</h3>
                    </p>
                    <div className='control multi-inp'>
                        <p className='control short'>
                            <label>Ляв крак:</label>
                            <input
                                {...register("boneLength_radius_left")}
                                name="boneLength_radius_left"
                                type="number"
                            />
                        </p>
                        <div><img src={radius} className="control-img" /></div>
                        <p className='control short'>
                            <label>Десен крак:</label>
                            <input
                                {...register("boneLength_radius_right")}
                                name="boneLength_radius_right"
                                type="number"
                            />
                        </p>
                    </div>
                    <div className='action'>
                        <button className='cancel' onClick={handleSubmit(onPrevStep)}>⇠ Сантиметрия</button>
                        <button className="prev-next-step" onClick={handleSubmit(onNextStep)}>Резултат ⇢</button>
                    </div>
                    </>
                    }

                    {formStep != 11 && (
                    <div className='action'>
                        <button className="cancel" onClick={() => navigate('/diagnostics/' + formValues.id)}>Отказ</button>
                        <button className="finish" onClick={handleSubmit(onFinish)}>Финализирай</button>
                    </div>
                    )}
                
                {formStep === 11 && (
                    <>
                    <DiagnosticsSheetData data={{values: formValues, logo: authCtx.user.company.logo}} />
                    <section className="lists">
                        <div className='action'>
                            <button className='cancel' onClick={handleSubmit(onPrevStep)}>⇠ Кости</button>
                        </div>
                        <div className="action">
                            <button className="cancel" onClick={() => navigate('/diagnostics/' + formValues.id)}>Отказ</button>
                            <button className="finish" onClick={handleSubmit(onFinish)}>Финализирай</button>
                        </div>
                    </section>
                </>
                )}

            </section>
            </form>
        </>
    )
}

export default DiagnosticsForm;