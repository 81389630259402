const SpineModel = {
    spineN: '1',
    spineSLL: '2',
    spineSLR: '3',
    spineSTL: '4',
    spineSTR: '5',
    spineSLRTL: '6',
    spineSLLTR: '7',
    spineSLLTL: '8',
    spineSLRTR: '9',
    spineK: '10',
    spineLL: '11',
    spineLLK: '12',
    spineLLKLC: '13',
    spineHLL: '14',
    spineHLLK: '15',
    spineHLLKLC: '16',
    spineLC: '17',
    spineLCK: '18',
    spineLCHLL: '19',
    spineLCLL: '20',
    spineLCFLL: '21',
    getSpineString: (spine) => {
        switch (spine) {
            case SpineModel.spineN: 
                return 'в норма';
            case SpineModel.spineSLL: 
                return 'Сколиоза в лумбален дял наляво';
            case SpineModel.spineSLR: 
                return 'Сколиоза в лумбален дял надясно';
            case SpineModel.spineSTL: 
                return 'Сколиоза в торакален дял наляво';
            case SpineModel.spineSTR: 
                return 'Сколиоза в торакален дял надясно';
            case SpineModel.spineSLRTL: 
                return 'Сколиоза в лумбален дял надясно, в торакален дял наляво';
            case SpineModel.spineSLLTR: 
                return 'Сколиоза в лумбален дял наляво, в торакален дял надясно';
            case SpineModel.spineSLLTL: 
                return 'Сколиоза в лумбален дял наляво, в торакален дял наляво';
            case SpineModel.spineSLRTR: 
                return 'Сколиоза в лумбален дял надясно, в торакален дял надясно';
            case SpineModel.spineK: 
                return 'Кифоза';
            case SpineModel.spineLL: 
                return 'Изгладена поясна лордоза';
            case SpineModel.spineLLK: 
                return 'Изгладена поясна лордоза и кифоза';
            case SpineModel.spineLLKLC: 
                return 'Изгладена поясна лордоза, кифоза и изгладена шийна лородоза';
            case SpineModel.spineHLL: 
                return 'Хипер лордоза в лумбален дял';
            case SpineModel.spineHLLK: 
                return 'Хипер лордоза в лумбален дял и кифоза';
            case SpineModel.spineHLLKLC: 
                return 'Хипер лордоза в лумбален дял, кифоза и изгладена шийна лородоза';
            case SpineModel.spineLC: 
                return 'Изгладена шийна лородоза';
            case SpineModel.spineLCK: 
                return 'Изгладена шийна лородоза и кифоза';
            case SpineModel.spineLCHLL: 
                return 'Изгладена шийна лородоза и хипер лордоза в лумбален дял';
            case SpineModel.spineLCLL: 
                return 'Изгладена шийна лордоза и изгладена поясна лордоза';
            case SpineModel.spineLCFLL: 
                return 'Изгладена шийна лордоза, плосък гръб и изгладена поясна лордоза';
        }
    }
};

export default SpineModel;