export function convertBase64(file) {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
        resolve(fileReader.result);
        }
        fileReader.onerror = (error) => {
        reject(error);
        }
    })
}

export function convertToProperDate(inputDate) {
    const date = new Date(inputDate);

    return `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}.${date.getFullYear()}`;
}

export function musclesEvaluation(faultyMuscle, goniometryType, refMin, refMax, valueLeft, valueRight) {
    switch (true) {
        case (valueLeft === null && valueRight === null):
            return '';
        case (valueLeft === refMax && valueRight === refMax):
            return 'в норма';
        case (valueLeft > refMax && valueRight > refMax && valueLeft === valueRight):
            return <>слабост {faultyMuscle && ` в ${faultyMuscle}`}</>;
        case (valueLeft > refMax && valueRight <= refMax):
            return <>слабост отляво {faultyMuscle && ` в ${faultyMuscle}`}</>;
        case (valueLeft <= refMax && valueRight > refMax):
            return <>слабост отдясно {faultyMuscle && ` в ${faultyMuscle}`}</>;
        case (valueLeft > refMax && valueRight > refMax && valueLeft === valueRight && (valueLeft - refMax) <= 3 && (valueRight - refMax) <= 3):
            return <>лека слабост {faultyMuscle && ` в ${faultyMuscle}`}</>;
        case (valueLeft > refMax && valueRight > refMax && valueLeft != valueRight && (valueLeft - refMax) < (valueRight - refMax)):
            return <>слабост повече отдясно {faultyMuscle && ` в ${faultyMuscle}`}</>;
        case (valueLeft > refMax && valueRight > refMax && valueLeft != valueRight && (valueLeft - refMax) > (valueRight - refMax)):
            return <>слабост повече отляво {faultyMuscle && ` в ${faultyMuscle}`}</>;
        case (valueLeft < refMax && valueRight < refMax && valueLeft === valueRight):
            return 'скъсена мускулатура';
        case (valueLeft === refMax && valueRight < refMax):
            return 'скъсяване отдясно';
        case (valueLeft < refMax && valueRight >= refMax):
            return 'скъсяване отляво';
        case (valueLeft < refMax && valueRight < refMax && valueLeft != valueRight && valueLeft < valueRight):
            return 'скъсяване повече отляво';
        case (valueLeft < refMax && valueRight < refMax && valueLeft != valueRight && valueLeft > valueRight):
            return 'скъсяване повече отдясно';
    }
};

export function musclesEvaluationSingle(faultyMuscle, goniometryType, refMin, refMax, value) {
    switch (true) {
        case (value === null):
            return '';
        case (value <= refMax && value >= refMin):
            return 'в норма';
        case (value > refMax && (value - refMax) <= 3):
            return <>лека слабост {faultyMuscle && ` в ${faultyMuscle}`}</>;
        case (value > refMax && (value - refMax) > 3):
            return <>слабост {faultyMuscle && ` в ${faultyMuscle}`}</>;
        case (value < refMin):
            return 'скъсена мускулатура';
    }
};

export function musclesEvaluationLatRot(faultyMuscle, goniometryType, refMin, refMax, valueLeft, valueRight) {
    switch (true) {
        case (valueLeft === null && valueRight === null):
            return '';
        case (valueLeft === refMax && valueRight === refMax):
            return 'в норма';
        case (valueLeft > refMax && valueRight > refMax && valueLeft === valueRight && (valueLeft - refMax) <= 3 && (valueRight - refMax) <= 3):
            return <>лека слабост {faultyMuscle && ` в ${faultyMuscle}`}</>;
        case (valueLeft > refMax && valueRight > refMax && valueLeft === valueRight):
            return <>слабост {faultyMuscle && ` в ${faultyMuscle}`}</>;
        case (valueLeft > refMax && valueRight > refMax && valueLeft != valueRight && (valueLeft - refMax) < (valueRight - refMax)):
            return <>слабост повече отляво {faultyMuscle && ` в ${faultyMuscle}`}</>;
        case (valueLeft > refMax && valueRight > refMax && valueLeft != valueRight && (valueLeft - refMax) > (valueRight - refMax)):
            return <>слабост повече отдясно {faultyMuscle && ` в ${faultyMuscle}`}</>;
        case (valueLeft < refMax && valueRight < refMax && valueLeft === valueRight):
            return 'скъсена мускулатура';
        case (valueLeft >= refMax && valueRight < refMax):
            return 'скъсяване отляво';
        case (valueLeft < refMax && valueRight >= refMax):
            return 'скъсяване отдясно';
        case (valueLeft < refMax && valueRight < refMax && valueLeft != valueRight && valueLeft < valueRight):
            return 'скъсяване повече отдясно';
        case (valueLeft < refMax && valueRight < refMax && valueLeft != valueRight && valueLeft > valueRight):
            return 'скъсяване повече отляво';
    }
};

export function goniometryEvaluation(joint, goniometryType1, goniometryType2, refGonType1Max, refGonType2Max, gonType1Value, gonType2Value) {
    if (goniometryType1 != 'екстензия') {
        switch (true) {
            case (gonType1Value === null || gonType2Value === null):
                return '';
            case (gonType1Value >= refGonType1Max && gonType2Value >= refGonType2Max):
                return <span className='alert-ok'>в норма</span>;
            case (gonType1Value < refGonType1Max && gonType2Value < refGonType2Max):
                return <span className='alert-def'>намален обем на движение</span>;
            case (gonType1Value < refGonType1Max && gonType2Value >= refGonType2Max):
                return <span className='alert-def'>намален обем на движение при {`${goniometryType1}`}</span>;
            case (gonType1Value >= refGonType1Max && gonType2Value < refGonType2Max):
                return <span className='alert-def'>намален обем на движение при {`${goniometryType2}`}</span>;
    }
    } else {
        switch (true) {
            case (gonType1Value === null || gonType2Value === null):
                return '';
            case (gonType1Value === refGonType1Max && gonType2Value === refGonType2Max):
                return <span className='alert-ok'>в норма</span>;
            case (gonType1Value < refGonType1Max && gonType2Value < refGonType2Max):
                return <span className='alert-def'>намален обем на движение</span>;
            case (gonType1Value < refGonType1Max && gonType2Value === refGonType2Max):
                return <span className='alert-def'>намален обем на движение при {`${goniometryType1}`}</span>;
            case (gonType1Value >= refGonType1Max && gonType2Value < refGonType2Max):
                return <span className='alert-def'>намален обем на движение при {`${goniometryType2}`}</span>;
        }

    }
};

export function goniometryLeftRight(joint, directionL, directionR, refMin, refMax, valueLeft, valueRight) {
    switch (true) {
        case (valueLeft === null || valueRight === null):
            return '';
        case (valueLeft > refMax && valueRight > refMax):
            return <span className='alert-ok'>в норма</span>;
        case (valueLeft >= refMin && valueRight >= refMin && valueLeft <= refMax && valueRight <= refMax):
            return <span className='alert-ok'>в норма</span>;
        case (valueLeft < refMin && valueRight < refMin):
            return <span className='alert-def'>намален обем на движение</span>;
        case (valueLeft < refMin && valueRight >= refMin && valueRight <= refMax):
            return <span className='alert-def'>намален обем на движение {`${directionL}`}</span>;
        case (valueRight < refMin && valueLeft >= refMin && valueLeft <= refMax):
            return <span className='alert-def'>намален обем на движение {`${directionR}`}</span>;
    }
};

export function goniometryLeftRightSingleRef(joint, directionL, directionR, ref, valueLeft, valueRight) {
    switch (true) {
        case (valueLeft === null || valueRight === null):
            return '';
        case (valueLeft >= ref && valueRight >= ref):
            return <span className='alert-ok'>в норма</span>;
        case (valueLeft < ref && valueRight < ref):
            return <span className='alert-def'>намален обем на движение</span>;
        case (valueLeft < ref && valueRight >= ref):
            return <span className='alert-def'>намален обем на движение {`${directionL}`}</span>;
        case (valueRight < ref && valueLeft >= ref):
            return <span className='alert-def'>намален обем на движение {`${directionR}`}</span>;
    }
};